import dayjs from 'dayjs';

export const getStoredDate = (key: string): string | null => 
    localStorage.getItem(key);

export const setStoredDate = (key: string, date: string | null): void => {
    if (date) {
        // Parse the input date and ensure it has a day component
        const parsedDate = parseDateFromString(date);
        // Always format with day component (setting to 1st if not present)
        const formattedDate = parsedDate.date() === 1 && date.indexOf('/01/') === -1 
            ? parsedDate.format('MM/01/YYYY') 
            : parsedDate.format('MM/DD/YYYY');
        
        localStorage.setItem(key, formattedDate);
    }
};

export const formatDateToString = (date: dayjs.Dayjs | null): string | null => 
    date?.format('MM/DD/YYYY') || null;

export const parseDateFromString = (dateString: string | null): dayjs.Dayjs => {
    if (!dateString) return dayjs().startOf('month');
    
    const parsedDate = dayjs(dateString);
    
    // Check if the dateString is in MM-YYYY format (no day component)
    if (dateString.match(/^\d{2}-\d{4}$/)) {
        const [month, year] = dateString.split('-');
        return dayjs(`${year}-${month}-01`);
    }
    
    return parsedDate;
};