import React from 'react';
import { TextField } from '@mui/material';

interface TextAnswerProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
}

const TextAnswer: React.FC<TextAnswerProps> = ({ value, onChange, disabled = false, multiline = true, rows = 3 }) => {
  return (
    <TextField
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      sx={{
        width: '50%',
        minWidth: '40px',
        '& .MuiInputBase-root': {
          maxHeight: '150px',
          overflow: 'auto',
          resize: 'both'
        },
        '& .Mui-disabled': {
          backgroundColor: '#f5f5f5',
          WebkitTextFillColor: '#666666'
        }
      }}
      variant="outlined"
      size="medium"
      multiline={multiline}
      rows={rows}
    />
  );
};

export default TextAnswer;