import React from 'react';
import { withAuthCheck } from '../../components/AuthCheck/AuthCheck';
import ContactTable from '../../components/ContactTable/ContactTable.component';

const Admin: React.FC = () => {
    return (
        <div style={{ paddingTop: '64px', paddingLeft: '86px', paddingRight: '86px' }}>
            <ContactTable />
        </div>
    );
};

export default withAuthCheck(Admin);