import { Typography } from 'bx-ui';
import React from 'react';

const DefaultLegalComplianceCard: React.FC = () => {
    return (
        <Typography id="instruction" bxVariant = 'bx-typography-body-primary'>
            <h2>Before you begin</h2>
            <p>Please refer to the definitions below for the <b>CONFLICTS OF INTEREST</b> section.</p>
            <p><b>Family:</b>This includes, but is not limited to, individuals related by ancestry, marriage, or adoption, whether or not the same household is shared, and any other person with whom the same household is shared.</p>
            <p><b>Financial Service Company:</b>This includes any entity that is engaged in the business of providing financial services, including, without limitation, broker, dealers, investment advisers (registered and unregistered), commercial banks, investment banks, private investment funds (including hedge funds and private equity funds), custodians, and administrators.</p>
            <p><b>Service Provider:</b>This includes any person who or entity that provides any services to your Firm or its Funds, including, without limitation, any broker, dealer, administrator, law firm, accounting firm, advisor, or consultant.</p>
            <p><b>Control Position:</b>This includes any position where an individual has the power, directly or indirectly, to direct the management or policies of the Firm or its investments, including, without limitation, any Partner, Chairman, Chief Executive Officer, General Counsel, Portfolio Manager, Risk Manager, Chief Operating Officer, Chief Financial Officer, Controller, Chief Compliance Officer.</p>
            <p>Please refer to the definitions below for the <b>DISCIPLINARY AND FINANCIAL MATTERS</b> section.</p>
            <p><b>Covered Person:</b>Covered Person with respect to the Firm means its (i) directors, executive officers and any other officers participating in the offering of shares of funds managed by the Firm or shares of funds managed by Blackstone Alternative Asset Management L.P., or any other Blackstone investment advisor (in the Firm's capacity as sub-advisor to such fund) (each, a "Fund"), (ii) any general partner or managing member of the Firm and (iii) any directors, executive officers and any other officers of any such general partner or managing member of the investment manager participating in the offering of Fund shares.</p>
        </Typography>
    );
};

export default DefaultLegalComplianceCard;
