import * as React from 'react';
import { useState } from 'react';
import { BackgroundCheckCardProps } from "./BackgroundCheckCardProps";
import { DocumentType } from '../Common/Document';
import { CircularProgress, FormControl, Stack } from '@mui/material';
import { Typography, Button, IconButton, TextInput } from 'bx-ui';
import VisuallyHiddenInput from '../Common/VisuallyHiddenInput';
import { useUpdateData, useUploadDocument } from '../../services/DataQueryService';
import { useAuth } from 'react-oidc-context';
import { downloadDocument } from '../../utils/documentUtils';
import { useSnackbar } from '../Common/SnackbarProvider';
import { AttachFile, Save } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const BackgroundCheckInvoiceTab = (props: BackgroundCheckCardProps) => {
    const bgcrid = props.bgcRequestId;
    const access_token = useAuth().user?.access_token;
    const putMutation = useUpdateData();

    const [invoiceAmount, setInvoiceAmount] = useState(props.invoiceAmount);
    const [prevInvoiceAmount, setPrevInvoiceAmount] = useState(props.invoiceAmount);
    const [reportEta, setReportEta] = useState(props.reportEta);
    const [prevReportEta, setPrevReportEta] = useState(props.reportEta);
    const [hasAttachedInvoice, setHasAttachedInvoice] = useState(props.hasAttachedInvoice);
    const [invoiceDocumentId, setInvoiceDocumentId] = useState(props.invoiceDocumentId);
    const { showSnackbar, closeSnackbar } = useSnackbar();

    const uploadMutation = useUploadDocument();
    const submitFinalReport = () => {
        if (!confirm("Are you sure?")) return;

        putMutation.reset();
        putMutation.mutate({
            access_token,
            method: 'POST',
            path: `/background-check/${bgcrid}`,
            data: null
        },
            {
                onSuccess: (data) => {
                    props.onDataUpdated(bgcrid, 'submited');
                    window.location.reload();
                },
                onError: (error) => {
                    showSnackbar(error.message, 'error');
                }
            });
    };

    const updateReportEta = (reportEta: string) => {
        putMutation.reset();
        putMutation.mutate({
            access_token,
            method: 'PUT',
            path: `/background-check/${bgcrid}/eta`,
            data: { reportEta: reportEta }
        },
            {
                onSettled: (data, error) => {
                    if (data?.successful) {
                        setPrevReportEta(prevReportEta);
                        setReportEta(reportEta);
                        props.onDataUpdated(bgcrid, reportEta);
                    } else if (error) {
                        setReportEta(prevReportEta);
                    }
                },
                onSuccess: (data) => {
                    showSnackbar('Successfully updated report ETA.', 'success');
                },
                onError: (error) => {
                    showSnackbar(error.message, 'error');
                }
            });
    }

    const updateInvoiceAmount = (invoiceAmount: number) => {
        putMutation.reset();
        putMutation.mutate({
            access_token,
            method: 'PUT',
            path: `/background-check/${bgcrid}/invoice`,
            data: { invoiceAmount: invoiceAmount }
        },
            {
                onSettled: (data, error) => {
                    if (data?.successful) {
                        setPrevInvoiceAmount(prevInvoiceAmount);
                        setInvoiceAmount(invoiceAmount);
                        props.onDataUpdated(bgcrid, invoiceAmount);
                    } if (error) {
                        setInvoiceAmount(prevInvoiceAmount);
                    }
                },
                onSuccess: (data) => {
                    showSnackbar('Successfully updated invoice amount.', 'success');
                },
                onError: (error) => {
                    showSnackbar(error.message, 'error');
                }
            });
    }


    const handleInvoiceUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        putMutation.reset();
        uploadMutation.reset();
        uploadMutation.mutate({
            access_token,
            bgcrid,
            documentType: DocumentType.BackgroundCheckInvoice,
            file
        },
            {
                onSettled: (data) => {
                    if (data?.successful) {
                        setHasAttachedInvoice(true);
                        setInvoiceDocumentId(data.data.id);
                        props.onDataUpdated(bgcrid, data.data.id);
                    }
                },
                onSuccess: (data) => {
                    showSnackbar('Successfully uploaded invoice attachment.', 'success');
                },
                onError: (error) => {
                    showSnackbar(error.message, 'error');
                }
            });
    };

    const handleInvoiceClick = (invoiceDocumentId: string) => {
        downloadDocument(access_token, DocumentType.BackgroundCheckInvoice, bgcrid, invoiceDocumentId);
    };

    return (
        <Typography bxVariant = 'bx-typography-body-primary' sx={{ width: '100%' }}>
            <Stack direction="column" spacing={1}>
                <FormControl sx={{ width: '100%', pb: 0.4 }}>
                    <Stack direction="row" spacing={1} alignItems="flex-end" justifyContent="space-between" sx={{ width: '100%' }}>
                        <TextInput
                            id='eta'
                            type='date'
                            label='ETA'
                            inputProps={{ readOnly: !props.isEditable }}
                            value={reportEta || ''}
                            onChange={(event) => setReportEta(event.target.value)}
                            disabled={!props.isEditable}
                            sx={{
                                ...(props.isEditable ? { width: '350px' } : { flexGrow: 1 }),
                                '& .MuiInputBase-root': {
                                    '&.Mui-disabled': {
                                        backgroundColor: '#f5f5f5',
                                        '& > input': {
                                            backgroundColor: '#f5f5f5'
                                        }
                                    }
                                }
                            }} />
                        {props.isEditable && (
                            <IconButton bxVariant="secondary"
                                disabled={putMutation.isPending}
                                onClick={() => updateReportEta(reportEta)}
                                sx={{ marginLeft: 'auto' }}
                            >
                                {putMutation.isPending ? <CircularProgress size={24} /> : <Save />}
                            </IconButton>
                        )}</Stack>
                </FormControl>

                <FormControl sx={{ width: '100%', py: 0.8 }}>
                    <Stack direction="row" spacing={1} alignItems="flex-end" justifyContent="space-between" sx={{ width: '100%' }}>
                        <TextInput
                            id='invoiceAmount'
                            type='number'
                            label='Invoice Amount'
                            inputProps={{ readOnly: !props.isInvoiceEditable }}
                            value={invoiceAmount || 0}
                            onChange={(event) => setInvoiceAmount(parseFloat(event.target.value))}
                            disabled={!props.isInvoiceEditable}
                            sx={{
                                ...(props.isInvoiceEditable ? { width: '350px' } : { flexGrow: 1 }),
                                '& .MuiInputBase-root': {
                                    '&.Mui-disabled': {
                                        backgroundColor: '#f5f5f5',
                                        '& > input': {
                                            backgroundColor: '#f5f5f5'
                                        }
                                    }
                                }
                            }} />
                        {props.isInvoiceEditable && (
                            <IconButton bxVariant="secondary"
                                disabled={putMutation.isPending}
                                onClick={() => updateInvoiceAmount(invoiceAmount)}
                                sx={{ marginLeft: 'auto' }}
                            >
                                {putMutation.isPending ? <CircularProgress size={24} /> : <Save />}
                            </IconButton>
                        )}</Stack>
                </FormControl>

                <FormControl sx={{ width: '100%', py: 1, minHeight: '36px'}}>
                    {
                        props.isInvoiceEditable && (
                            <Stack direction="row" spacing={1} alignItems="flex-end" justifyContent="space-between"
                                sx={{
                                    top: '8px',
                                    width: '100%',
                                    height: '100%'
                                }}>
                                <Typography bxVariant='bx-typography-body-primary'sx={{ width: '350px', fontSize: '14px', lineHeight: 1, alignSelf: 'center', display: 'flex', justifyContent: 'flex-end' }} >
                                    {hasAttachedInvoice ? 'Update Invoice Document' : 'Upload Invoice Document'}
                                </Typography>
                                <IconButton bxVariant="secondary"
                                    edge="end"
                                    component="label"
                                    disabled={uploadMutation.isPending}
                                    sx={{ alignSelf: 'flex-end' }}
                                >
                                    {uploadMutation.isPending ?
                                        <CircularProgress size={24} /> :
                                        <AttachFile />}
                                    <VisuallyHiddenInput
                                        type='file'
                                        id='selectedFile'
                                        onChange={(event) => handleInvoiceUpload(event)}
                                    />
                                </IconButton>
                            </Stack>
                        )
                    }
                </FormControl>

                <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ pt: 0.5 }}>
                    {
                        hasAttachedInvoice && (
                            <div style={{ display: 'flex', justifyContent: props.isEditable ? 'flex-start' : 'flex-end', width: '100%' }}>
                                <Button onClick={() => handleInvoiceClick(invoiceDocumentId)}
                                    sx={{
                                        width: '205px'
                                    }}
                                    endIcon={<FileDownloadIcon />}>
                                    View Invoice Document
                                </Button>
                            </div>

                        )
                    }
                    {
                        props.isEditable &&
                        (<div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <Button
                                variant="contained"
                                disabled={putMutation.isPending}
                                onClick={() => submitFinalReport()}
                                sx={{
                                    width: 'auto'
                                }}
                            >
                                Submit Final Report
                            </Button>
                        </div>)
                    }
                </Stack>

            </Stack >
        </Typography >
    );
};

export default BackgroundCheckInvoiceTab;