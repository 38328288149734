import Config from '../config/appConfig';
import { DocumentType } from "../components/Common/Document";

export const downloadDocument = (access_token: string | undefined, documentType: DocumentType, bgcRequestId?: string, documentLocatorId?: string, onCompletion?: () => void) => {
    const locatorId = documentLocatorId ?? 'N3oyZw==';
    const url = bgcRequestId !== undefined && bgcRequestId !== '' ?
        `${Config.DataApiUrl}/background-check/${bgcRequestId}/document?documentType=${documentType}&documentLocatorId=${locatorId}` :
        `${Config.DataApiUrl}/legal-compliance/document?documentType=${documentType}&documentLocatorId=${locatorId}`;
    
    fetch(url, {
        headers: {
            'Authorization': `Bearer ${access_token}`
        }
    })
        .then(response => {
            const contentDisposition = response.headers.get('content-disposition');
            const filename = contentDisposition ? contentDisposition.match(/filename=([^;]*)/)?.at(1)?.replace(/"/g, '') || 'document' : 'document';
            return { blob: response.blob(), filename };
        })
        .then(({ blob, filename }) => {
            return blob.then(blobData => {
                const url = window.URL.createObjectURL(blobData);
                const link = window.document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                link.click();
                window.URL.revokeObjectURL(url);
                if (onCompletion)
                    onCompletion();
            });
        });};
