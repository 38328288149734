import React from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import { Typography, Button } from 'bx-ui';
import NumericAnswer from '../Answer/NumericAnswer.component';
import PercentAnswer from '../Answer/PercentAnswer.component';
import BooleanAnswer from '../Answer/BooleanAnswer.component';
import TextAnswer from '../Answer/TextAnswer.component';
import DateAnswer from '../Answer/DateAnswer.component';
import SingleChoiceAnswer from '../Answer/SingleChoiceAnswer.component';

const ANSWER_COMPONENTS: { [key: string]: any } = {
    'NUMERIC': NumericAnswer,
    'PERCENT': PercentAnswer,
    'BOOLEAN': BooleanAnswer,
    'TEXT': TextAnswer,
    'DATE': DateAnswer,
    'SINGLE CHOICE': SingleChoiceAnswer,
};

export interface Question {
    questionId: string;
    displayText: string;
    answerDataType: string;
    answerDataTypeId: string;
    answerDataTypeName: string;
    defaultValue?: string;
    possibleAnswers?: string[];
    answers?: Answer[];
    initalAnswers?: Answer[];
    isUpdated: boolean;
}

export interface Answer {
    answerId?: string;
    answerText: string;
    index: number;
    questionId?: string;
    sequenceNumber: number;
    isUpdated?: boolean;
}

interface DynamicQuestionProps {
    questionText: string;
    answerType: string;
    answers: Answer[];
    setAnswers: (answers: Answer[]) => void;
    allowMulltipleAnswers?: boolean;
    disabled?: boolean;
    isEditable: boolean;
    answerIndex?: number;
    options?: string[];
}

const QuestionText = ({ text }: { text: string }) => (
    <Typography bxVariant="bx-typography-body-primary">
        {text}
    </Typography>
);

const DynamicQuestion: React.FC<DynamicQuestionProps> = ({
    questionText,
    answerType,
    answers,
    setAnswers,
    allowMulltipleAnswers,
    isEditable,
    answerIndex,
    options = []
}) => {
    const AnswerComponent = ANSWER_COMPONENTS[answerType];
    allowMulltipleAnswers = allowMulltipleAnswers && (answerIndex === undefined || answerIndex < 0);

    if (!allowMulltipleAnswers && answerIndex && answerIndex >= 0 && answerIndex > answers.length - 1) {
        const misingAnswers = Array.from(Array(answerIndex - (answers.length - 1)).keys()).map(i => { return { answerText: '', sequenceNumber: -1, index: -1 }; });
        answers = [...answers, ...misingAnswers];
    }

    function updateAnswer(answerIndex: number, answerText: any) {
        const updatedAnswers = answers.map((answer, i) => {
            if (i === answerIndex) {
                return { ...answer, answerText, index: i, isUpdated: true };
            }
            return answer;
        });
        setAnswers(updatedAnswers);
    }

    function removeAnswer(answerIndex: number): void {
        setAnswers(answers.filter((answer, i) => i !== answerIndex));
    }

    function addAnswer(): void {
        setAnswers([
            ...answers,
            { answerText: '', sequenceNumber: -1, index: -1 }
        ]);
    }

    return (
        <Box sx={{ mb: 2 }}>
            <QuestionText text={questionText} />
            <Box sx={{ mt: 1 }}>
                <Stack direction="column" spacing={2}>
                    {answers.map((answer, i) => (allowMulltipleAnswers || i === (answerIndex ?? 0)) && (
                        <Stack direction="row" spacing={2} key={answer.answerId}>
                            <AnswerComponent
                                value={answer.answerText}
                                onChange={value => updateAnswer(i, value)}
                                disabled={!isEditable}
                                options={options}
                            />
                            {isEditable && allowMulltipleAnswers && i === answers.length - 1 && (<Tooltip title="Add Answer"><Button component="label" onClick={() => addAnswer()}>+</Button></Tooltip>)}
                            {isEditable && allowMulltipleAnswers && answers.length > 1 && (<Tooltip title="Delete Answer"><Button component="label" onClick={() => removeAnswer(i)}>-</Button></Tooltip>)}
                        </Stack>
                    ))}
                </Stack>
            </Box>
        </Box>
    );
};

export default DynamicQuestion;