import React from 'react';
import { ProgressCircle } from 'bx-ui/components';

interface CenteredLoadingProps {
  size?: string;
}

/**
 * A component that displays a centered loading spinner on the full viewport
 */
const CenteredLoading: React.FC<CenteredLoadingProps> = ({ size }) => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%'
    }}>
      <ProgressCircle size={size} />
    </div>
  );
};

export default CenteredLoading;
