import * as React from 'react';
import { useAuth } from 'react-oidc-context';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { BackgroundCheckCardProps } from "./BackgroundCheckCardProps";
import { DocumentType } from '../Common/Document';
import DynamicForm, { RequestType } from '../DynamicForm/DynamicForm.component';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from '@mui/material';
import { Typography, Button } from 'bx-ui';
import { downloadDocument } from '../../utils/documentUtils';

const BackgroundCheckRequestTab = (props: BackgroundCheckCardProps) => {
    const bgcrid = props.bgcRequestId;
    const access_token = useAuth().user?.access_token;
    const entityDictionary = {
        ...(props.performsCompanyReview ? { [props.company.id]: props.company.name } : {}),
        ...Object.fromEntries(props.contacts?.map(contact => [contact.id, contact.name]) || [])
    };

    const [openFormDialog, setOpenFormDialog] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState('');
    const [openBioDialog, setOpenBioDialog] = React.useState(false);
    const [selectedBio, setSelectedBio] = React.useState('');
    const [isExporting, setIsExporting] = React.useState(false);
    const [closeQuestionnaireRequested, setCloseQuestionnaireRequested] = React.useState(false);

    const handleNameClick = (contactId: string) => {
        setSelectedId(contactId);
        setOpenFormDialog(true);
    };

    const handleCloseForm = () => {
        setCloseQuestionnaireRequested(true);
    };

    const handleBioClick = (bio: string) => {
        setSelectedBio(bio);
        setOpenBioDialog(true);
    };

    const handleConsentClick = (consentFormDocumentId: string) => {
        downloadDocument(access_token, DocumentType.BackgroundCheckConsent, bgcrid, consentFormDocumentId);
    };

    const handleReportClick = () => {
        setIsExporting(true);
        downloadDocument(access_token, DocumentType.BackgroundCheckReport, bgcrid, undefined, () => { setIsExporting(false) });
    };

    const handleCloseBio = () => {
        setOpenBioDialog(false);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Typography bxVariant="bx-typography-headline-secondary" gutterBottom onClick={() => {
                if (props.performsCompanyReview) {
                    setSelectedId(props.company.id)
                    setOpenFormDialog(true)
                }
            }}
                sx={{
                    ...(props.performsCompanyReview ? { cursor: 'pointer', '&:hover': { textDecoration: 'underline' } } : {}),
                    paddingBottom: '8px',
                    borderBottom: '1px solid black'
                }}>
                {props.company.name}
            </Typography>

            <TableContainer component={Paper} sx={{
                height: '360px',
                overflow: 'auto',
                '&.MuiPaper-root': {
                    boxShadow: 'none !important'
                }
            }}>
                <Table stickyHeader size="small" aria-label="contacts table">
                    <TableBody>
                        {props.contacts?.map((contact, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { borderLeft: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography bxVariant='bx-typography-body-primary'
                                        sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                                        onClick={() => handleNameClick(contact.id)}
                                    >
                                        {contact.name}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    {contact.hasBio && (
                                        <Typography bxVariant='bx-typography-body-primary'
                                            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                                            onClick={() => handleBioClick(contact.bio)}
                                        >
                                            View Bio
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {contact.hasConsentForm && (
                                        <Typography bxVariant='bx-typography-body-primary'
                                            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                                            onClick={() => handleConsentClick(contact.consentFormDocumentId)}
                                        >
                                            Consent Form
                                        </Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={openFormDialog}
                onClose={handleCloseForm}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    {selectedId === props.company.id
                        ? props.company.name
                        : props.contacts.find(contact => contact.id === selectedId)?.name}
                </DialogTitle>
                <DialogContent>
                    <DynamicForm
                        requestType={RequestType.BackgroundCheck}
                        requestId={props.bgcRequestId}
                        entityId={selectedId}
                        setQuestionnaireOpen={setOpenFormDialog}
                        closeQuestionnaireRequested={closeQuestionnaireRequested}
                        setCloseQuestionnaireRequested={setCloseQuestionnaireRequested}
                        isEditable={props.isEditable}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseForm}>Close</Button>
                </DialogActions>            </Dialog>

            <Dialog
                open={openBioDialog}
                onClose={handleCloseBio}
                maxWidth="sm"
                fullWidth
                aria-labelledby="bio-dialog-title"
            >
                <DialogTitle id="bio-dialog-title">Biography</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedBio}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseBio}>Close</Button>
                </DialogActions>
            </Dialog>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto', 
                padding: '4px', paddingBottom: '60px' }}>
                <Button
                    variant="contained"
                    endIcon={isExporting ?
                        <CircularProgress size="20px" /> :
                        <FileDownloadIcon />
                    }
                    onClick={handleReportClick}
                    disabled={isExporting}
                >
                    Export Background Check Report
                </Button>
            </div>
        </div>
    );
};

export default BackgroundCheckRequestTab;