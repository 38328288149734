export interface DocumentList{
    isEditable: boolean;
    documents: Document[];
    bgcRequestId?: string;
    onAttachmentsChange?: (bgcRequestId: string, attachments: Document[]) => void;
}

export interface Document{
    id: string;
    fullName: string;
    created: string;
    modified: string;
    data: Uint8Array[];
    mimeType: string;
}

export enum DocumentType {
    Undefined = 0,
    BackgroundCheckInvoice = 1,
    BackgroundCheckAttachment = 2,
    BackgroundCheckConsent = 3,
    BackgroundCheckReport = 4,
    LegalComplianceReport = 5,
    LegalComplianceAttachment = 6
}

