import React, {useEffect, useState} from 'react';
import { Typography } from 'bx-ui';
import {Roles, UserInfo} from "../Common/UserInfoResponse";

const UserRoles : React.FC<{userInfo: UserInfo | undefined}> = ({userInfo}) => {
    if (!userInfo) {
        return <Typography bxVariant = "bx-typography-headline-primary">Loading user roles...</Typography>;
    }
    
    const roleToDisplay = 
        userInfo?.hasRole(Roles.BackgroundCheck) ? 'Background Check' : 
        userInfo?.hasRole(Roles.LegalCompliance) ? 'Legal Compliance' : 'No Roles Present';
    
    return (
        <Typography bxVariant = "bx-typography-headline-primary">
            {roleToDisplay}
        </Typography>
    );
};

export default UserRoles;