import { Box, Card, CardContent, CardHeader, Grid, Stack } from "@mui/material";
import { Typography } from "bx-ui";
import { ProgressCircle } from "bx-ui/components"
import React, { useEffect, useState } from 'react';
import { useAuth } from "react-oidc-context";
import { useNavigate } from 'react-router-dom';
import styles from "./AutoRedirect.module.scss";
import BackgroundImage from '../../assets/login-splash.jpg';
import blackstoneLogo from "../../assets/Blackstone.png";
import CenteredLoading from "../Loading/CenteredLoading";

const Navbar = () => {
  return (
    <Stack className={styles.navbar}>
      <Box
        id={styles.logo}
        component="img"
        alt={'The Blackstone Group'}
        src={blackstoneLogo}
      />
    </Stack>
  );
};

export const AutoRedirect = (props: {noRedirect?: boolean}) => {

  if (!props.noRedirect) {
    const auth = useAuth();
    const navigate = useNavigate();
    const [isRedirecting, setIsRedirecting] = useState(false);

    useEffect(() => {
      // Prevent redirect loops by checking if we're already redirecting
      if (isRedirecting) return;
      
      if (auth.isAuthenticated && !auth.user?.expired) {
        // If user is authenticated, redirect to home
        setIsRedirecting(true);
        navigate('/', { replace: true });
      } else if (!auth.isLoading) {
        // Only redirect if auth loading is complete and we're not authenticated
        setIsRedirecting(true);
        auth.signinRedirect();
      }
    }, [auth, navigate, isRedirecting]);
  }
  
  if (location.pathname !== '/login') {
    return <CenteredLoading />;
  }

  return (
    <Box>
      <Navbar />    
      <Box sx={{
        backgroundImage:`url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: 'block',
        position: 'absolute',      
        width: '100%',
        minHeight: 'calc(100vh - 64px)',      
        top: '64px',
      }}>
        <Grid container id={styles.authContainer}>
          <Card className={styles.card}>
            <CardHeader
              title={
                <Typography bxVariant="bx-typography-title-large-primary">
                </Typography>
              }
              className={styles.header}
            />
            <CardContent className={styles.content}>
                <CenteredLoading  />
            </CardContent>
            
          </Card>
        </Grid>
      </Box>
    </Box>
  );
};

export default AutoRedirect;