import React, {useEffect, useState} from 'react';
import {Box, List, ListItem} from '@mui/material';
import UserRoles from '../UserRoles/UserRoles.component';
import DatePickerComponent from '../Common/DatePicker';
import {getStoredDate, parseDateFromString, setStoredDate} from '../../utils/dateUtils';
import {useSearchParams} from 'react-router-dom';
import {Roles, UserInfo} from "../Common/UserInfoResponse";

interface PageTitleProps {
    onDateChange: (date: string | null) => void;
    userInfo: UserInfo | undefined;
}

const PageTitle: React.FC<PageTitleProps> = ({onDateChange, userInfo }) => {
    const [searchParams, setSearchParams] = useSearchParams(new URLSearchParams(window.location.search));

    const [selectedDate, setSelectedDate] = React.useState<string | null>(
        searchParams.get('period') || getStoredDate('selectedDate') || null
    );

    useEffect(() => {
        if (selectedDate) {
            setStoredDate('selectedDate', selectedDate);
            setSearchParams({period: parseDateFromString(selectedDate).format('MM-YYYY')});
            onDateChange(selectedDate);
        }
    }, [selectedDate]);

    if (!userInfo || !userInfo.hasRole(Roles.BackgroundCheck)) return null;

    return (
        <Box sx={{
            position: 'absolute',
            top: '64px',
            width: '100%',
            color: 'black'
        }}>
            <List 
                component="div"
                sx={{
                    display: 'flex', 
                    padding: 0, 
                    width: '100%',
                    boxShadow: 'none !important',
                    bgcolor: 'transparent',
                    borderBottom: 'none',
                    alignItems: 'flex-end',
                    position: 'relative',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: '100px', 
                        right: 0,
                        height: '1px',
                        backgroundColor: '#e0e0e0' 
                    }
                }}
                disablePadding
            >
                <ListItem sx={{width: '50%', paddingLeft: '100px'}}>
                    <UserRoles userInfo={userInfo} />
                </ListItem>
                <ListItem sx={{width: '90%', justifyContent: 'flex-end' }}>
                    <DatePickerComponent
                        value={selectedDate}
                        onDateChange={(date) => setSelectedDate(date)}
                        views={['year', 'month']}
                        openTo="year"
                    />
                </ListItem>
            </List>
        </Box>    );
};
export default PageTitle;