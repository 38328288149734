import React, {useEffect, useState} from 'react';
import { Stack, Box, Tabs, Tab, Card, List, ListItem, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { Button, Typography } from 'bx-ui';
import BackgroundCheckAttachmentsTab from '../BackgroundCheckCard/BackgroundCheckAttachmentsTab.component';
import { hashCode, useDataQuery, useUpdateData } from '../../services/DataQueryService';
import { useAuth } from 'react-oidc-context';
import DynamicForm, { RequestType } from '../DynamicForm/DynamicForm.component';
import { LegalComplianceCardProps } from './LegalComplianceCardProps';
import { downloadDocument } from '../../utils/documentUtils';
import { Document, DocumentType } from "../Common/Document";
import { useTabState } from '../../utils/tabUtils';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { UserInfo } from "../Common/UserInfoResponse";


interface LegalComplainceResponse {
    data: LegalComplianceCardProps;
    successful: boolean;
}

const LegalComplianceCard: React.FC<{ userInfo: UserInfo | undefined }> = ({ userInfo }) => {
    const putMutation = useUpdateData();
    const [openDialog, setOpenDialog] = useState(false);
    const { selectedTab, handleTabChange } = useTabState('selectedLegalComplianceTab', 0);
    const [attachments, setAttachments] = useState<Document[]>([]);
    const access_token = useAuth().user?.access_token;
    const [isExporting, setIsExporting] = useState(false);

    if (userInfo?.email == null)
        return <></>;

    const userHash = userInfo.email + userInfo?.companyId;
    const { data, isLoading, error } = useDataQuery<LegalComplainceResponse>(
        access_token,
        '/legal-compliance',
        { refresh: 'true', userHash: hashCode(userHash) }
    );

    useEffect(() => {
        if (data) {
            setAttachments(data.data.documents);
        }
    }, [data]);

    if (isLoading) return null;
    if (error) return null;
    if (!data) return null;

    const legalComplianceCardProps = data.data;
    const questionnaire = legalComplianceCardProps.questionnaire;
    const requestYear = new Date(questionnaire.requestDate).getFullYear();

    const questionnaireResponse = {
        data: {
            sections: questionnaire.sections
        }
    };

    const handleSubmitReport = () => {
        setOpenDialog(true);
    };

    const handleConfirmSubmit = () => {
        setOpenDialog(false);
        putMutation.reset();
        putMutation.mutate({
            access_token,
            method: 'POST',
            path: `/legal-compliance`,
            data: null
        },
            {
                onSuccess: (data) => { window.location.reload(); }
            });
    };

    const handleExportReport = () => {
        setIsExporting(true);
        downloadDocument(
            access_token,
            DocumentType.LegalComplianceReport,
            undefined,
            undefined,
            () => { setIsExporting(false) });
    };

    return (
        <Box sx={{ paddingLeft: '86px' }}>
            {legalComplianceCardProps.isLegalComplianceSubmitted ? (
                <Typography bxVariant='bx-typography-body-large-primary'>
                    <p>Started in: {questionnaire.requestDate}</p>
                    <p>The {requestYear} Legal/Compliance Supplement for {questionnaire.entityName} has been submitted.</p>
                    <p>Legal/Compliance Company</p>
                </Typography>
            ) : (
                <Typography bxVariant='bx-typography-body-primary'>
                    <Stack spacing={2}>
                        <Box>
                            <List sx={{
                                display: 'flex',
                                padding: 0,
                            width: '100%',
                                boxShadow: 'none !important',
                                bgcolor: 'transparent',
                                borderBottom: '1px solid #e0e0e0',
                                alignItems: 'flex-end'
                        }}>
                                <ListItem sx={{
                                    width: '90%', fontSize: '16px',
                                    display: 'flex',
                                    paddingLeft: 0,
                                    alignItems: 'flex-end'
                                }}>
                                    {questionnaire.entityName}
                                </ListItem>
                                <ListItem sx={{ width: '25%', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        onClick={handleSubmitReport}
                                        sx={{ width: 'auto' }}
                                    >
                                        Submit Report
                                    </Button>
                                </ListItem>
                                <ListItem sx={{ width: '30%', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        disabled={isExporting}
                                        onClick={handleExportReport}
                                        sx={{ mt: 1, mb: 0 }}
                                        startIcon={isExporting ?
                                            <CircularProgress size="20px" /> :
                                            <FileDownloadIcon />
                                        }
                                    >
                                        Export to Microsoft Words
                                    </Button>
                                </ListItem>
                            </List>
                        </Box>
                        <Box>
                            <Tabs value={selectedTab} onChange={(_, value) => handleTabChange(value)} TabIndicatorProps={{ sx: { display: 'none' } }}>
                                <Tab label="Questionnaire" />
                                <Tab label="Attachments" />
                            </Tabs>
                            {selectedTab === 0 && (
                                <DynamicForm
                                    requestType={RequestType.LegalCompliance}
                                    requestId={legalComplianceCardProps.reviewId}
                                    entityId={questionnaire.entityId}
                                    questionnaire={questionnaireResponse}
                                    isEditable={legalComplianceCardProps.isEditable}
                                />
                            )}
                            {selectedTab === 1 && (
                                <Card sx={{ maxWidth: '450px', padding: 3, boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.25)' }}>
                                    <BackgroundCheckAttachmentsTab
                                        documents={attachments}
                                        isEditable={legalComplianceCardProps.isEditable}
                                        onAttachmentsChange={(notUsed, d) => setAttachments(d)} />
                                </Card>
                            )}
                        </Box>
                    </Stack>

                    <Dialog
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                    >
                        <DialogTitle>SUBMIT LEGAL/COMPLIANCE</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to submit the Legal/Compliance Supplement? Please note that once the supplement has been submitted you will not be able to edit or view it. Only submit when you're completed comfortable and satisfied with your answers.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                            <Button onClick={handleConfirmSubmit} autoFocus>OK</Button>
                        </DialogActions>
                    </Dialog>
                </Typography>
            )}
        </Box>


    );
}; export default LegalComplianceCard;
