import React from 'react';
import { useAuth } from 'react-oidc-context';
import { 
    Paper,      
    Stack,
    Avatar,
    Box
} from '@mui/material';
import { Typography } from 'bx-ui';
import { withAuthCheck } from '../../components/AuthCheck/AuthCheck';

const Profile = () => {
    const { user } = useAuth();
    const profile = user?.profile;

    return (
        <Typography bxVariant='bx-typography-body-large-primary'>
            <Box sx={{ p: 4 }}>
            <Paper elevation={3} sx={{ p: 4, maxWidth: 600, mx: 'auto' }}>
                <Stack spacing={3} alignItems="center">
                    <Avatar 
                        src={profile?.picture}
                        sx={{ width: 100, height: 100 }}
                    />
                    
                    <Typography bxVariant="bx-typography-headline-primary" gutterBottom>
                        {profile?.name}
                    </Typography>

                    <Stack spacing={2} width="100%">
                        <Typography variant='bx-typography-body-large-primary'>
                            <strong>Email:</strong> {profile?.email}
                        </Typography>
                        <Typography variant='bx-typography-body-large-primary'>
                            <strong>Given Name:</strong> {profile?.given_name}
                        </Typography>
                        <Typography variant='bx-typography-body-large-primary'>
                            <strong>Family Name:</strong> {profile?.family_name}
                        </Typography>
                    </Stack>
                </Stack>
            </Paper>
        </Box>
            </Typography>
    );
};

export default withAuthCheck(Profile);
