import React, {useEffect, useState} from 'react';
import { Route, Routes, BrowserRouter, Navigate, useNavigate } from 'react-router-dom';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './styles/styles.scss';
import AppBarComponent, { IAppBarItem } from './components/AppBar/AppBar.component';
import Home from './pages/Home/Home';
import HealthCheck from './pages/HealthCheck/HealthCheck';
import 'bx-ui/bx-ui.css';
import { BXThemeProvider } from "bx-ui/theme"
import { ErrorDialogProvider, useErrorDialog } from './components/Common/ErrorDialog';
import Profile from './pages/Profile/Profile';
import NotFound from './pages/Error/NotFound';
import { useAuth } from 'react-oidc-context';
import { useAuthCheck } from './utils/authUtils';
import Admin from './pages/Admin/Admin';
import {UserInfoProvider, useUserInfoContext} from "./services/UserInfoService";
import {Roles, UserInfo} from "./components/Common/UserInfoResponse";
import {SnackbarProvider} from "./components/Common/SnackbarProvider";
import AuthRedirector from "./components/Common/AuthRedirector";
import AutoRedirect from './components/AutoRedirect/AutoRedirect';

const QueryClientErrorProvider = function ({ children }: { children: React.ReactNode }) {
  const errorDialog = useErrorDialog();
  const { checkAuthAndRedirect } = useAuthCheck();
  
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        if (!checkAuthAndRedirect()) {
          return;
        }
        errorDialog.setState({
          errorMessage: error?.message ?? "",
          showError: true,
          showRetryButton: true
        });
      }
    })
  });
  
  return (<QueryClientProvider client={queryClient}>
    {children}
  </QueryClientProvider>);
};

function App() {
  const auth = useAuth();
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const userInfoContext = useUserInfoContext();
  
  useEffect(() => {
    if (userInfoContext?.userInfo) 
      setUserInfo(userInfoContext.userInfo);
  }, [userInfoContext]);
  
  // Don't render until auth is done loading
  if (auth.isLoading) {
    return <AutoRedirect noRedirect />;
  }

  return (
    <BXThemeProvider>
        <ErrorDialogProvider>
          <BrowserRouter>
            <QueryClientErrorProvider>
              {!auth.isAuthenticated || auth.user?.expired ? (
                <Routes>
                  <Route path="/login" element={<AutoRedirect />} />
                  <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
              ) : (
                <>
                  <AuthRedirector userInfo={userInfo} />
                  <AppBarComponent userInfo={userInfo} ></AppBarComponent>
                  <Routes>
                    <Route index key="Home" path="/" element={<Home userInfo={userInfo} />} />
                    <Route index key="Login" path="/login" element={<Navigate to="/" replace />} />
                    {userInfo?.hasRole(Roles.Admin) && <Route index key="admin" path="/admin" element={<Admin />} />}
                    <Route index key="Profile" path="/profile" element={<Profile />} />
                    {userInfo?.hasRole(Roles.Admin) && <Route path="/healthcheck" element={<HealthCheck />} />}
                    {userInfo && <Route path="*" element={<NotFound />} />}
                  </Routes>
                </>
              )}
            </QueryClientErrorProvider>
          </BrowserRouter>
        </ErrorDialogProvider>
    </BXThemeProvider>
  );
}
// Had to create a wrapper because UserInfoProvider needs to be at the top
const AppWrapper = () => ( 
    <UserInfoProvider> 
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </UserInfoProvider>
);
export default AppWrapper;
