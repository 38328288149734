import { Alert, AlertTitle, Backdrop, Stack } from "@mui/material";
import { Button } from 'bx-ui';
import React, { createContext, useContext } from "react";

export interface ErrorDialogState {    
    errorTitle?: string;
    errorMessage: string;
    showError: boolean;
    showRetryButton?: boolean;
    retryButtonLabel?: string;
    retryButtonAction?: () => void;    
}

export interface ErrorDialogProps {
    state: ErrorDialogState;
    setState: (state: ErrorDialogState) => void;
}

const defaultErrorDialogState = {  } as ErrorDialogProps;

const ErrorDialogContext = createContext(defaultErrorDialogState);
export const useErrorDialog = () => useContext(ErrorDialogContext);

const ErrorDialog = () => {
    const { state, setState } = useContext(ErrorDialogContext);
    const retryAction = () => {
        if (state.retryButtonAction)
            state.retryButtonAction();
        else
             window.location.reload();
    }

    return (<Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={state.showError || false}>
                <Stack color="inherit" direction="column" spacing={2} >
                    <Alert severity={ "error" }
                    action={
                        state.showRetryButton ? (<Button color="inherit" size="small" onClick={() => retryAction() }>
                                {state.retryButtonLabel ?? "RETRY"}</Button>) : (<Button variant="text" component="label"  size="small"  onClick={() => { setState({ showError: false, errorMessage: ''}) } }>X</Button>)
                    }>
                        <AlertTitle>{state.errorTitle ?? "Something went wrong. Try reloading."}</AlertTitle>
                        {state.errorMessage}
                    </Alert>
                </Stack>
            </Backdrop>);
}

export const ErrorDialogProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, setState] = React.useState({ } as ErrorDialogState);        
    return (<ErrorDialogContext.Provider value={{state, setState}}>
        {children}        
        <ErrorDialog />
    </ErrorDialogContext.Provider>);
}