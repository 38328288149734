import * as React from 'react';
import { Card, CardHeader, CardContent, Box } from '@mui/material';
import { Typography } from 'bx-ui';
import { Tabs, Tab } from 'bx-ui/components';
import { useBXTheme } from 'bx-ui/theme';
import dayjs from 'dayjs';
import { BackgroundCheckCardProps } from "./BackgroundCheckCardProps";
import BackgroundCheckInvoiceTab from './BackgroundCheckInvoiceTab.component';
import BackgroundCheckAttachmentsTab from './BackgroundCheckAttachmentsTab.component';
import BackgroundCheckRequestTab from './BackgroundCheckRequestTab.component';

const BackgroundCheckCard = (props: BackgroundCheckCardProps) => {
    const { palette } = useBXTheme();

    const requestedDate = !!props.dateReportRequested ? dayjs(props.dateReportRequested).format('MM/DD/YYYY') : "-";
    const reportEtaDate = !!props.reportEta ? dayjs(props.reportEta).format('MM/DD/YYYY') : "-"
    const requestedDateSubtitle = `Requested: ${requestedDate}`;
    const reportEtaDateSubtitle = `ETA: ${reportEtaDate}`;

    const [selectedTab, setSelectedTab] = React.useState(0);
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                style={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    display: value === index ? 'block' : 'none'
                }}
                {...other}
            >
                {value === index && <Box sx={{ 
                    p: 3, 
                    height: '100%', 
                    overflow: 'hidden'
                }}>{children}</Box>}
            </div>
        );
    }

    function tabProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <Card sx={{ width: 450, height: 450, margin: 2, boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.25)',display: 'flex', flexDirection: 'column'}} raised>
                <CardHeader
                    sx={{ padding: 2 }}
                    title={
                        <Typography bxVariant='bx-typography-title-primary'
                            sx={{
                                lineHeight: 1.5,
                                textTransform: 'capitalize',
                                paddingBottom: '8px'
                            }}>
                            {props.company.name}<br />
                        </Typography>
                    }
                    subheader={
                        <Typography bxVariant='bx-typography-headline-secondary'
                            sx={{
                                lineHeight: 1.6
                            }}>
                            {requestedDateSubtitle}<br />
                            {reportEtaDateSubtitle}
                        </Typography>
                    }
                />
                <CardContent sx={{
                    padding: '0px 0px 0px 8px',
                    '&:last-child': {
                        paddingBottom: 0
                    },
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Tabs value={selectedTab}
                            onChange={handleChange}
                        >
                            <Tab label="Requests" {...tabProps(0)} />
                            <Tab label="Invoice" {...tabProps(1)} />
                            <Tab label="Attachments" {...tabProps(2)} />
                        </Tabs>
                        <CustomTabPanel value={selectedTab}
                            index={0}>
                            <BackgroundCheckRequestTab {...props} />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab}
                            index={1}>
                            <BackgroundCheckInvoiceTab {...props} />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab}
                            index={2}>
                            <BackgroundCheckAttachmentsTab
                                documents={props.attachments}
                                isEditable={props.isEditable}
                                bgcRequestId={props.bgcRequestId}
                                onAttachmentsChange={props.onDataUpdated} />
                        </CustomTabPanel>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};
export default BackgroundCheckCard;