import React from 'react';
import DatePickerComponent from '../Common/DatePicker';

interface DateAnswerProps {
  value: string | null;
  onChange: (value: string | null) => void;
  disabled?: boolean;
}

const DateAnswer: React.FC<DateAnswerProps> = ({ value, onChange, disabled=false}) => {
  const handleDateChange = (dateString: string | null) => {
    onChange(dateString);
  };

  return (!disabled ? <DatePickerComponent
    value={value}
    onDateChange={handleDateChange}
    views={['year', 'month', 'day']}
    openTo="day"
  /> : null
  );
};
export default DateAnswer;
