import React from "react";

export const useTabState = (storageKey: string, defaultValue: number = 0) => {
    const [selectedTab, setSelectedTab] = React.useState(() => {
        const storedValue = localStorage.getItem(storageKey);
        return storedValue ? parseInt(storedValue) : defaultValue;
    });

    const handleTabChange = React.useCallback((newValue: number) => {
        setSelectedTab(newValue);
        localStorage.setItem(storageKey, String(newValue));
    }, [storageKey]);

    return { selectedTab, handleTabChange };
};