import { useAuthCheck } from '../../utils/authUtils';
import React, { useEffect } from "react";
import { useAuth } from 'react-oidc-context';

export const withAuthCheck = (WrappedComponent: React.ComponentType) => {
  return function WithAuthCheck(props: any) {
    const auth = useAuth();
    const { checkAuthAndRedirect } = useAuthCheck();

    useEffect(() => {
      checkAuthAndRedirect();
    }, [auth.isAuthenticated, auth.user?.expired]);

    return <WrappedComponent {...props} />;
  };
}; 