import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import { TextField, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Select, Backdrop, CircularProgress } from '@mui/material';
import { Typography, Button } from 'bx-ui';
import { useDataQuery, useUpdateData } from '../../services/DataQueryService';
import { useAuth } from 'react-oidc-context';
import { useQueryClient } from '@tanstack/react-query';
import { IconButton, Menu, MenuItem } from 'bx-ui';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSnackbar } from '../Common/SnackbarProvider';

interface HedgehogContactResponse {
  totalRows: number,
  data: [
    {
      contactId: number;
      contactName: string;
      webUserName: string;
      companyId: number;
      companyName: string;
      email: string;
      emails: string[];
      employee: boolean;
      formerEmployee: boolean;
      legalRep: boolean;
      backgroundCheckProvider: boolean;
      cognitoUserId: string;
      cognitoUserName: string;
      mfaEmailAddress: string;
      status: string;
    }
  ];
}

interface ContactTableRow {
    id: string;
    selected: boolean;
    hedgehogContactId: string;
    contactName: string;
    emailAddress: string;
    emailAddresses: string[];
    legacyWebUsername: string;
    cognitoUserName: string;
    mfaEmail: string;
    validationMessage: string;    
    cognitoSetupDone: boolean;
    cognitoUserId: string;
    userEnabled: boolean;
}

const ActionMenu = (props: { row: ContactTableRow, refreshGridCallBack: (response: any) => void }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const access_token = useAuth().user?.access_token
    const updateUserMutation = useUpdateData();
    const { showSnackbar, closeSnackbar } = useSnackbar();
    
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCreateCognitoUser = () => {
        updateUserMutation.mutate({
            access_token: access_token,
            method: 'POST', 
            path: '/admin/contact',
            data: [{
                cognitoUserName: props.row.cognitoUserName,
                mfaEmailAddress: props.row.mfaEmail,
            }]
        },
        {
            onSettled: (result) => {
                props.refreshGridCallBack(result);
            },
            onSuccess: (data) => {
                
                showSnackbar(`Successfully created Cognito user: "${props.row.contactName}".`, 'success');
            },
            onError: (error) => {
                showSnackbar(error.message, 'error');
            }
        });
        handleClose();
    }

    const handleEnableDisableUser = () => {

        if (!confirm(`Are you sure you want to ${props.row.userEnabled ? 'Disable' : 'Enable'} user '${props.row.contactName}'?`)) return;

        updateUserMutation.mutate({
            access_token: access_token,
            method: 'POST',
            path: props.row.userEnabled ? '/admin/contacts/disable' : '/admin/contacts/enable',
            data: [props.row.cognitoUserName]
        },
        {
            onSettled: (result) => {
                props.refreshGridCallBack(result);
            },
            onSuccess: (data) => {
                let verb = props.row.userEnabled ? 'disabled' : 'enabled';
                showSnackbar(`Successfully ${verb} user "${props.row.contactName}"`, 'success');
            },
            onError: (error) => {
                showSnackbar(error.message, 'error');
            }
        });

        handleClose();
    }

    const handleResetPassword = () => {
        if (!confirm(`Are you sure you want to reset the password of user '${props.row.contactName}'?`)) return;

        updateUserMutation.mutate({
            access_token: access_token,
            method: 'POST',
            path: '/admin/contacts/reset-password',
            data: props.row.cognitoUserName
        },
        {
            onSettled: (result) => {
                props.refreshGridCallBack(result);
            },
            onSuccess: (data) => {
                showSnackbar(`Successfully reset password for user "${props.row.contactName}"`, 'success');
            },
            onError: (error) => {
                showSnackbar(error.message, 'error');
            }
        });
        handleClose();
    }

    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              style: {
                maxHeight: 48 * 4.5,
                width: '20ch',
              },
            },
          }}
        >
          {!props.row.cognitoUserId && !props.row.validationMessage && (<MenuItem onClick={handleCreateCognitoUser}>
              Create Cognito User
          </MenuItem>)}

          {props.row.cognitoSetupDone &&  (<MenuItem onClick={handleEnableDisableUser}>
              {props.row.userEnabled ? 'Disable User' : 'Enable User'}
          </MenuItem>) }         
          
          {
            /* Disable reset password because it does not work in cognito yet.
                {props.row.cognitoSetupDone && props.row.userEnabled && (<MenuItem onClick={handleResetPassword}>
                    Reset Password
                </MenuItem>)}
            */
          }

          { (props.row.cognitoUserId || props.row.validationMessage) &&
            (<MenuItem onClick={() => props.refreshGridCallBack(null)}>
                Refresh
            </MenuItem>)}          
        </Menu>

        <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={updateUserMutation.isPending}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </div>
    );
}

const ContactTable: React.FC = () => {
    const [rows, setRows] = useState<ContactTableRow[]>([]);    
    const [successDialog, setSuccessDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
    const { showSnackbar, closeSnackbar } = useSnackbar();
    
    const queryClient = useQueryClient();
    const updateUsersMutation = useUpdateData();
    const access_token = useAuth().user?.access_token
      
      const contactsQuery = useDataQuery<HedgehogContactResponse>(
            access_token,
            '/admin/edp-contacts'
      );

      const { data, isLoading, isSuccess } = contactsQuery;
      const refreshGrid = function(result: any) {
        queryClient.removeQueries({ queryKey: ['/admin/edp-contacts'] });
        contactsQuery.refetch();
      }

      const GridToolbar = () => {
        return(<GridToolbarContainer>            
        </GridToolbarContainer>)
    }

      useEffect(() => {
        const newRows = data?.data?.map(r => {            
            const mfaEmailAddress = r.mfaEmailAddress === 'ERROR!' ? '' : r.mfaEmailAddress;            
            let validationMessage = !r.emails?.length || r.emails.some(email => !email?.trim())  ? 'Please add email address in Hedgehog first.' : (r.emails?.some(email => 
                email?.toLowerCase().includes('@blackstone.com')
              ) ? 'BX Employee should log in directly with Okta and no need to create Cognito account' : '');
            
              if (!validationMessage && r.status != 'Enabled' && r.status != 'Disabled')
              {
                validationMessage = r.status;
              }


            return {
                id: `${r.companyId}-${r.contactId}`,
                selected: false,
                hedgehogContactId: `${r.contactId}`,
                contactName: r.contactName,                
                emailAddress: r.email ?? r.emails?.join(','),
                emailAddresses: r.emails,
                legacyWebUsername: r.webUserName,
                cognitoUserName: r.cognitoUserName || '',
                mfaEmail: mfaEmailAddress || '',
                validationMessage: validationMessage,
                cognitoUserId: r.cognitoUserId,
                userEnabled: r.status == 'Enabled',
                cognitoSetupDone: Boolean(r.cognitoUserId && mfaEmailAddress && !validationMessage)
            } as ContactTableRow;
        }) ?? [];
        
        setRows(newRows);
      }, [data, isSuccess]);

      useEffect(() => {
            let selectRowCount = rows.filter(row => row.selected && row.mfaEmail && !row.cognitoUserId).length;
            setCreateButtonDisabled(!selectRowCount);
      }, [rows])

      const rowCountRef = React.useRef(data?.totalRows || 0);
      const rowCount = React.useMemo(() => {
        if (data?.totalRows !== undefined) {
          rowCountRef.current = data?.totalRows;
        }
        return rowCountRef.current;
      }, [data?.totalRows]);

    const columns: GridColDef[] = [
        {
            field: 'selected',
            headerName: 'Select',
            width: 100,
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.selected}
                    onChange={() => handleRowSelection(params.row.id)}
                    disabled={params.row.cognitoSetupDone || Boolean(params.row.validationMessage)}
                />
            )
        }, 
        { field: 'hedgehogContactId', headerName: 'HedgeHog Contact ID', width: 180 },
        { field: 'contactName', headerName: 'HedgeHog Contact Name', width: 200 },
        { field: 'emailAddress', headerName: 'HedgeHog Contact Email Address', width: 250 },
        { field: 'legacyWebUsername', headerName: 'Legacy Webusername', width: 180 },
        { field: 'cognitoUserName', headerName: 'Cognito User Name', width: 180 },
        { field: 'mfaEmail', headerName: 'MFA Email Address', width: 200, editable: true, renderCell: (params) => {            
            return params.row.selected ? (<Select value={params.value} label='MFA Email Address' onChange={(e) => handleUpdateMfaEmail(params.row.id, e.target.value)}>
                {
                    params.row.emailAddresses?.map(email => { return (<MenuItem value={email}>{email}</MenuItem>) })
                }
            </Select>) : (<div>{params.value}</div>)
        }},
        {
            field: 'validationMessage', headerName: 'Validation Message', width: 200, flex: 1,
            renderCell: (params) => (
                <div style={{ color: '#d32f2f' }}>
                    {params.value}
                </div>
            )
        },
        { field: 'action', headerName: 'Actions', renderCell: (params) => {
            return (<ActionMenu row={params.row} refreshGridCallBack={refreshGrid} />)
        }},
    ];
    
    const handleUpdateMfaEmail = (id: string, newMfaEmail: string) => {
        const currentRow = rows.find(row => row.id === id);
        if (!currentRow) return;

        setRows(prevRows => prevRows.map(row => {
            if (row.id === id) {
                return {
                    ...row,
                    mfaEmail: newMfaEmail
                };
            }
            return row;
        }));
    }

    const handleRowSelection = async (id: string) => {        
        const currentRow = rows.find(row => row.id === id);
        if (!currentRow) return;

        if (!currentRow.selected) {
            setRows(prevRows => prevRows.map(row => {
                if (row.id === id) {
                    return {
                        ...row,                        
                        selected: true, // Validation happens at load time.
                        cognitoUserName: row.legacyWebUsername,
                        mfaEmail: row.emailAddress
                    };
                }
                return row;
            }));
        } else {
            // Just unselect
            setRows(prevRows => prevRows.map(row =>
                row.id === id ? { ...row, selected: false, validationMessage: '' } : row
            ));
        }
    };    

    const handleCreateCognitoUsers = async () => {
        // Update rows with default email if cognitoUserName is empty
        setRows(prevRows => prevRows.map(row => {
            if (row.selected && !row.cognitoUserName) {
                return {
                    ...row,
                    cognitoUserName: row.emailAddress
                };
            }
            return row;
        }));

        const selectedUsers = rows.filter(row => row.selected).map(row => { 
            return { 
                cognitoUserName: row.cognitoUserName, 
                mfaEmailAddress: row.mfaEmail,
            } 
        });
    
        updateUsersMutation.mutate({
            access_token: access_token,
            method: 'POST',
            path: '/admin/contact',
            data: selectedUsers
        },
            {
                onSuccess: (result) => {
                    showSnackbar('Cognito user(s) successfully created.', 'success');
                    refreshGrid(null);
                },
                onError: (error) => {
                    showSnackbar(error.message, 'error');
                }
            });
    };
    
    return (
        <Typography bxVariant='bx-typography-body-small-primary' style={{ height: 600, width: '100%' }}>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={updateUsersMutation.isPending}>
                <CircularProgress color="inherit" />
            </Backdrop>   

            <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography bxVariant="bx-typography-title-large-primary" component="h1" sx={{ fontWeight: 'bold' }}>
                        Contact Management
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={createButtonDisabled}
                        onClick={handleCreateCognitoUsers}>
                        Create Cognito User
                    </Button>
                </Box>
            </Paper>
            <DataGrid
                isCellEditable={(params) => {
                    return params.row.selected && !Boolean(params.row.mfaEmail);
                }}
                slots={{ toolbar: GridToolbar }}
                rows={rows}
                columns={columns}
                loading={isLoading}
                checkboxSelection={false}
                disableRowSelectionOnClick
                editMode="row"                
                initialState={ { pagination: { rowCount: -1,  paginationModel: { pageSize: 25 } }}}
                pageSizeOptions={[5, 10, 25, 50]}
                rowCount={rowCount}
                processRowUpdate={(updatedRow, originalRow) => {
                    setRows(prevRows => 
                        prevRows.map(row => 
                            row.id === updatedRow.id ? updatedRow : row
                        )
                    );
                    
                    return updatedRow;
                }}
                getRowClassName={(params) => {
                    return Boolean(params.row.cognitoSetupDone || params.row.validationMessage) ? 'disabled-row' : '';
                }}
                sx={{
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#f5f5f5',
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 700,
                            fontSize: '14px'
                        }
                    },
                    '& .disabled-row .MuiDataGrid-cell': {
                        backgroundColor: '#f5f5f5',
                        color: '#666666',
                        '&:hover': {
                            backgroundColor: '#f5f5f5'
                        }
                    }
                }}        />
            <Dialog
                open={successDialog}
                onClose={() => setSuccessDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Success
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ whiteSpace: 'pre-line' }}>
                        <Typography bxVariant='bx-typography-body-primary'>{successMessage}</Typography>
                        <br/>
                        {errorMessage && (
                            <Box>
                                <Typography bxVariant='bx-typography-body-primary'>The following errors happened:</Typography>
                                <Typography bxVariant='bx-typography-body-primary'>{errorMessage}</Typography>
                            </Box>)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSuccessDialog(false)} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Typography>
    );
};

export default ContactTable;
