import { Alert } from '@mui/material';
import { Button } from 'bx-ui';
import React from 'react';

interface ErrorAlertProps {
    buttonText: string;
    error: any;
    onRetry: () => void;
    customMessage?: string;
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({ buttonText, error, onRetry, customMessage }) => (
    <Alert 
        severity="error" 
        action={
            <Button 
                variant="text" 
                component="label" 
                size="small" 
                onClick={onRetry}
            >
                {buttonText}
            </Button>
        }
    >
        {error?.message || customMessage || "An error occurred"}
    </Alert>
);
