import React from 'react';
import { Box } from '@mui/material';
import { Typography, Button } from 'bx-ui';
import { useNavigate } from 'react-router-dom';
import { withAuthCheck } from '../../components/AuthCheck/AuthCheck';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '80vh',
                textAlign: 'center',
                gap: 3
            }}
        >
            <Typography bxVariant="bx-typography-title-large-primary" sx={{ fontSize: '6rem', fontWeight: 'bold' }}>
                404
            </Typography>
            <Typography bxVariant="bx-typography-title-secondary">
                Page Not Found
            </Typography>
            <Typography bxVariant="bx-typography-body-secondary" color="text.secondary">
                The page you are looking for does not exist.
            </Typography>
            <Button 
                variant="contained" 
                onClick={() => navigate('/')}
                sx={{ mt: 2 }}
            >
                Return to Home
            </Button>
        </Box>
    );
};

export default withAuthCheck(NotFound);
