import { AppEnvironment } from "@bx-ui-framework/authentication";
import { getEnvVar } from "../utils/getEnvVar";

export class OidcConfig {
    Authority: string;
    ClientID: string;
    ResponseType: string;
    Scope: string;
    LogoutRedirectUri: string;
    loadUserInfo: boolean;

    constructor(prefix: string) {
        this.Authority = getEnvVar(`${prefix}_OIDC_AUTHORITY`);
        this.ClientID = getEnvVar(`${prefix}_OIDC_CLIENTID`);
        this.ResponseType = getEnvVar(`${prefix}_OIDC_RESPONSE_TYPE`);
        this.Scope = getEnvVar(`${prefix}_OIDC_SCOPE`);
        this.LogoutRedirectUri = getEnvVar(`${prefix}_OIDC_LOGOUT_REDIRECT_URI`);
        this.loadUserInfo = prefix === 'okta' ? true : false;
    }
}

export type AppConfig = {
    Environment: AppEnvironment | undefined;
    AuthMethod: string;
    OidcConfigs: {
        [key: string]: OidcConfig;
    };
    DataApiUrl: string;
    HealthCheck: {
        [key: string]: string;
    };
    MaxFileSize: number;
    [key: string]: any | undefined;
}

export class AppConfigService {
    private _config: AppConfig;
    constructor() {
        this._config = this.loadConfig();
    }

    public get Config(): AppConfig {
        return this._config;
    }

    private loadConfig(): AppConfig {
        const config: AppConfig = {
            Environment: process.env.ENVIRONMENT as AppEnvironment,
            AuthMethod: process.env.AUTH_METHOD == null ? 'okta' : process.env.AUTH_METHOD,
            OidcConfigs: {
                okta: new OidcConfig('okta'),
                cognito: new OidcConfig('cognito')
            },
            DataApiUrl: process.env.DATA_API_URL || '',
            HealthCheck: {
                Auth: process.env.AUTH_API_HEALTH_CHECK_URL || '',
                Data: process.env.DATA_API_HEALTH_CHECK_URL || ''
            },
            MaxFileSize: parseInt(process.env.MAX_FILE_SIZE || '52428800')
        }
        return config;
    }
}

export default new AppConfigService().Config;