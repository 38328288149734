import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Config from '../config/appConfig';
import { DocumentType }  from '../components/Common/Document';
import { UserInfoResponse } from "../components/Common/UserInfoResponse";
function useDataQuery<T>(access_token: string | undefined, endpoint: string, params?: Record<string, string | null | undefined>, enabled: any = true) {
  
  return useQuery<T>({
    queryKey: [endpoint, params],
    queryFn: async () => {
      const queryString = params ? 
        `?${Object.entries(params)
          .filter(([_, value]) => value !== null)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}` 
        : '';
        
      const response = await fetch(`${Config.DataApiUrl}${endpoint}${queryString}`, {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      });

      return await handleResponse(response);      
    },
    enabled: !!enabled && !!enabled
  });
}


function useUploadDocument() {

  return useMutation({
    mutationFn: async ({access_token, bgcrid, documentType, file}: {access_token: string | undefined, bgcrid: string | undefined, documentType: DocumentType, file: File}) => {
      
      if (file.size > Config.MaxFileSize) {
        throw new Error("The selected file exceeds the max file size.");
      }

      const apiPath = bgcrid 
        ? `${Config.DataApiUrl}/background-check/${bgcrid}/document?documentType=${documentType}`
        : `${Config.DataApiUrl}/legal-compliance/document?documentType=${documentType}`;
 
      const formData = new FormData();
      formData.append('selectedFile', file);
        
      const response = await fetch(apiPath, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${access_token}`
        },
        body: formData
      });
        
      return await handleResponse(response);
    }
  });
}

function useDeleteDocument() {

  return useMutation({
    mutationFn: async ({access_token, bgcrid, documentType, documentId}: {access_token: string | undefined, bgcrid: string | undefined, documentType: DocumentType, documentId: string}) => {
      const apiPath = bgcrid 
        ? `${Config.DataApiUrl}/background-check/${bgcrid}/document?documentType=${documentType}&documentId=${documentId}`
        : `${Config.DataApiUrl}/legal-compliance/document?documentType=${documentType}&documentId=${documentId}`;

      const response = await fetch(apiPath, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      });

      return await handleResponse(response);
    }
  });
}

function useUpdateData() {
  
  return useMutation({
    mutationFn: async ({access_token, method, path, data }: {access_token: string | undefined,  method: string, path: string, data: any }) => {
      const response = await fetch(`${Config.DataApiUrl}${path}`, {
        method: method,
        headers: {
          'Authorization': `Bearer ${access_token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(data)
      });
            
      return await handleResponse(response);
    }
  });
}

function useUpdateImpersonation() {

  return useMutation({
    mutationFn: async ({ access_token, impersonationEmail, impersonationCompanyId }: { access_token: string | undefined, impersonationEmail: string, impersonationCompanyId: number}) => {
      
      const response = await fetch(`${Config.DataApiUrl}/impersonations/my?impersonationEmail=${impersonationEmail}&impersonationCompanyId=${impersonationCompanyId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${access_token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      return await handleResponse(response);
    }
  });
}

class ReponseErrorInfo
{
  public statusCode?: number;
}

async function handleResponse(response: Response): Promise<any> {   
  let responseData = { message: response.statusText ?? 'Unknown error', successful: false, traceId: '-' };
  try {
    responseData = await response.json();
  } catch { /* ignore */ }

  if (response.ok && responseData.successful) {
    return responseData;
  }
     
  let message = responseData.message;
  message = message.endsWith('.') ? message : message + '.';
  if (response.status >= 500 && response.status <= 599 && responseData.traceId) {
    message = `${message} TraceId: '${responseData.traceId}'`;
  } else if (response.status >= 400 && response.status <= 499 && !message) {
    message = `Status:${response.status}`
  }
  
  var errorInfo = new ReponseErrorInfo();
  errorInfo.statusCode = response.status;

  throw new Error(message, { cause: errorInfo });
}

const getErrorStatusCode = (error: Error) => {
  const cause = error.cause;
  if (cause instanceof ReponseErrorInfo)
  {
    return cause.statusCode;
  }
}

function hashCode(str: string | undefined): string {
  if (!str)
    return '';

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString();
}

export { useDataQuery, useUploadDocument, useDeleteDocument, useUpdateData, useUpdateImpersonation, handleResponse, hashCode, getErrorStatusCode };