import React from 'react';
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Collapse } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Config from '../../config/appConfig';
import { withAuthCheck } from '../../components/AuthCheck/AuthCheck';
import { Typography } from 'bx-ui';

interface ServiceStatus {
  name: string;
  url: string;
  responseCode: number;
  response: any;
}

const CollapsibleCell: React.FC<{ response: any, responseCode: number }> = ({ response, responseCode }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <TableCell>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Typography bxVariant='bx-typography-body-primary'>{responseCode}</Typography>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{
          maxHeight: '200px',
          overflow: 'auto',
          backgroundColor: '#f5f5f5',
          padding: '8px',
          marginTop: '8px',
          borderRadius: '4px',
          fontFamily: 'monospace'
        }}>
          <pre>
            {JSON.stringify(response, null, 2)}
          </pre>
        </Box>
      </Collapse>
    </TableCell>
  );
};

const HealthCheck: React.FC = () => {
  const [services, setServices] = React.useState<ServiceStatus[]>([]);

  React.useEffect(() => {
    const checkServices = async () => {
      const results = await Promise.all(
        Object.entries(Config.HealthCheck).map(async ([name, url]) => {
          try {
            const response = await fetch(`${url}`);
            return {
              name,
              url,
              responseCode: response.status,
              response: await response.json()
            };
          } catch (error) {
            return {
              name,
              url,
              responseCode: 500,
              response: error
            };
          }
        })
      );
      setServices(results);
    };

    checkServices();
  }, []);

  const getStatusIcon = (code: number) => {
    if (code >= 200 && code <= 399) {
      return <CheckCircleIcon sx={{ color: 'green' }} />;
    }
    if (code >= 400 && code <= 499) {
      return <ReportProblemIcon sx={{ color: 'yellow' }} />;
    }
    return <HighlightOffIcon sx={{ color: 'red' }} />;
  };

  return (
    <Stack spacing={7}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Service Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>URL</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Response</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service) => (
              <TableRow key={service.name}>
                <TableCell>{getStatusIcon(service.responseCode)}</TableCell>
                <TableCell>{service.name}</TableCell>
                <TableCell>{service.url}</TableCell>
                <CollapsibleCell response={service.response} responseCode={service.responseCode} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default withAuthCheck(HealthCheck);
