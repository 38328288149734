import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

interface BooleanAnswerProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const BooleanAnswer: React.FC<BooleanAnswerProps> = ({ value, onChange, disabled=false }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
      }
      label={value ? "Yes" : "No"}
    />
  );
};

export default BooleanAnswer;
