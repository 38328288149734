import React, { createContext, useContext, useState, useCallback, ReactNode } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
// Define types for a snackbar notification
interface SnackbarMessage {
    id: string;
    message: string;
    severity: AlertColor;
    open: boolean;
    autoHideDuration: number;
}

// Define context value types
interface SnackbarContextType {
    showSnackbar: (message: string, severity?: AlertColor) => string;
    closeSnackbar: (id: string) => void;
}

// Create Context
const SnackbarContext = createContext<SnackbarContextType | null>(null);

// Provider Component
interface SnackbarProviderProps {
    children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
    const [snackbars, setSnackbars] = useState<SnackbarMessage[]>([]);

    // Function to show a new snackbar
    const showSnackbar = useCallback((message: string, severity: AlertColor = "info", autoHideDuration?: number): string => {
        const id = uuidv4(); // Generate unique ID
        
        const duration = autoHideDuration ?? // Set default duration based on severity
            severity == "info" ? 3000 :
            severity == "success" ? 3000 : 
            severity == "warning" ? 10000 : 
            10000; // error
        setSnackbars((prev) => [...prev, { id, message, severity, open: true, autoHideDuration: duration }]);
        return id; // Return ID to allow closing manually
    }, []);

    // Function to close a specific snackbar
    const closeSnackbar = useCallback((id: string): void => {
        setSnackbars((prev) => prev.map(snack => snack.id === id ? { ...snack, open: false } : snack));
    }, []);

    return (
        <SnackbarContext.Provider value={{ showSnackbar, closeSnackbar }}>
            {children}
            {snackbars.map(({ id, message, severity, open, autoHideDuration }) => (
                <Snackbar
                    key={id}
                    open={open}
                    autoHideDuration={autoHideDuration}
                    onClose={() => closeSnackbar(id)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                    <Alert onClose={() => closeSnackbar(id)} severity={severity} variant="filled">
                        {message}
                    </Alert>
                </Snackbar>
            ))}
        </SnackbarContext.Provider>
    );
};

// Custom Hook to Use Snackbar
export const useSnackbar = (): SnackbarContextType => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error("useSnackbar must be used within a SnackbarProvider");
    }
    return context;
};