import React, {useState} from 'react';
import {
    Box,    
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, 
    Tooltip,
    Typography
} from '@mui/material';
import { Button } from 'bx-ui';
import { useUpdateImpersonation } from '../../services/DataQueryService';
import { useAuth } from 'react-oidc-context';
import {Badge, Cancel, Delete, UploadFile} from "@mui/icons-material";
import styles from "../AppBar/AppBar.module.scss";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {GridRowParams} from "@mui/x-data-grid-pro";
import {ImpersonationContact, UserInfo, UserInfoResponse} from "../Common/UserInfoResponse";
import {useImpersonationContactsContext, UserInfoContextType, useUserInfoContext} from "../../services/UserInfoService";
import { useSnackbar } from '../Common/SnackbarProvider';

const Impersonate: React.FC = () => {
    const [selectedContact, setSelectedContact] = useState<ImpersonationContact | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isInProgress, setIsInProgress] = useState<boolean>(false);
    const { showSnackbar, closeSnackbar } = useSnackbar();
    
    const updateImpersonationMutation = useUpdateImpersonation();
    const user = useAuth().user;
    const access_token = user?.access_token;
    const loggedInEmail = user?.profile?.email?.toLowerCase();
    const loggedInName = user?.profile?.name
    
    const userInfoContext : UserInfoContextType | undefined = useUserInfoContext();
    const impersonationContactsContext : ImpersonationContact[] | undefined = useImpersonationContactsContext();
    
    if (!userInfoContext || !userInfoContext.userInfo) return null;
    
    const impersonatedName = userInfoContext.userInfo.companyName ? 
        `${userInfoContext.userInfo.fullName} (${userInfoContext.userInfo.companyName})` :
        userInfoContext.userInfo.fullName;
    
    const isImpersonating = loggedInEmail != impersonatedName;
    const isTest = userInfoContext.userInfo.isTestUser ?? false;

    if (isTest && (!impersonationContactsContext || impersonationContactsContext.length == 0)) return null;
    
    const contactRows = impersonationContactsContext;
    
    const getFullName = (impersonatedContact: ImpersonationContact | null) : string => {
        return [impersonatedContact?.firstName, impersonatedContact?.middleName, impersonatedContact?.lastName]
            .map(n => n?.trim())
            .filter(n => n != null || n !== '')
            .join(' ');
    };

    const confirmImpersonation = () : void => {
        if (selectedContact != null) {
            setIsInProgress(true);
            updateImpersonationMutation.mutate({access_token, impersonationEmail: selectedContact.email , impersonationCompanyId: selectedContact.companyId},
                {
                    onSuccess: async (data: any) => {
                        await userInfoContext.fetchUserInfo(false);
                        showSnackbar(`Successfully impersonating User: ${selectedContact.firstName} ${selectedContact.lastName} / Company: ${selectedContact.companyName}`, 'success');
                        setSelectedContact(null);
                        setIsInProgress(false);
                        setIsDialogOpen(false);
                    },
                    onError: (error: Error) => {
                        setIsInProgress(false);
                        showSnackbar(error.message, 'error');
                    }
                });
        }
    };
    
    const contactColumns = [
        {
            field: 'companyName',
            headerName: 'Company',
            width: 300
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            width: 100
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            width: 100
        },
        {
            field: 'middleName',
            headerName: 'Middle Name',
            width: 50
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 300
        }
    ];

    if (isTest && isImpersonating) {
        return (
            <>
                <Tooltip title={`You are currently impersonating: ${impersonatedName || `` }`} arrow>
                    <Button variant="contained"
                            component="label"
                            startIcon={<Badge />}
                            onClick={() => setIsDialogOpen(true)}
                    >
                        {impersonatedName || ''} 
                    </Button>
                </Tooltip>
                
                <Dialog
                    open={isDialogOpen}
                    maxWidth="lg"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Impersonation"}
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={contactRows}
                                columns={contactColumns}
                                initialState={{
                                    pagination: { paginationModel: { pageSize: 100 } }
                                }}
                                getRowId={(row) => row.email + '_' + row.companyName}
                                onRowClick={(params : GridRowParams<ImpersonationContact>, event, details) => {
                                    setSelectedContact(params.row);
                                }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button startIcon={<Cancel/>}
                                onClick={() => setIsDialogOpen(false)}>
                            Cancel
                        </Button>
                        <Button startIcon={isInProgress ?
                                    <CircularProgress size="20px" /> :
                                    <Badge />
                                }
                                onClick={() => confirmImpersonation()}
                                disabled={!selectedContact}
                                autoFocus>
                            Impersonate: {getFullName(selectedContact) || ''}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    if (!isImpersonating) {
        return (
            <Tooltip title={loggedInName || ''} className={styles.toolbar_item_inactive}>
                <span>{loggedInName || ''}</span>
            </Tooltip>
        )
    }

    return null;    
};

export default Impersonate;