import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import PageTitle from '../../components/PageTitle/PageTitle.component';
import StatusTabs from '../../components/StatusTabs/StatusTabs.component';
import { withAuthCheck } from '../../components/AuthCheck/AuthCheck';
import LegalComplianceCard from '../../components/LegalComplianceCard/LegalComplianceCard.component';
import {Roles, UserInfo} from "../../components/Common/UserInfoResponse";
import Alert from '@mui/material/Alert';
import '../../styles/center-alert.css'
import { getErrorStatusCode } from '../../services/DataQueryService';
export const Home: React.FC<{userInfo: UserInfo | undefined}>= ({userInfo}) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  if (!userInfo) return (<></>);
  const getErrorMessage = () : string | undefined => {
      if (userInfo == undefined || userInfo.error == undefined)
        return undefined;
      if (getErrorStatusCode(userInfo.error) === 401 || getErrorStatusCode(userInfo.error) === 403) 
        return 'Currently you haven\'t been assigned the appropriate permissions to access this site.';
      return 'An error occurred while fetching user information: ' + userInfo.error.message;
  };
  
  const errorMessage = getErrorMessage();
  
  return (
    <Stack spacing={8}>
      <PageTitle onDateChange={setSelectedDate} userInfo={userInfo} />
      {errorMessage && (
          <div className="alert-overlay">
            <div className="alert-box">
              <Alert variant="filled" severity="error">
                {errorMessage}<br/><br/>
                Please contact <a href="mailto:hedgehogsupport@blackstone.com">HedgeHog Support</a> for assistance.
              </Alert>
            </div>
          </div>
      )}
      {userInfo.hasRole(Roles.BackgroundCheck) && (
        <StatusTabs period={selectedDate === undefined ? null : selectedDate} userInfo={userInfo} />)}
      {userInfo.hasRole(Roles.LegalCompliance) && (
        <LegalComplianceCard userInfo={userInfo}/>
      )}
      
    </Stack>
  );
};

export default withAuthCheck(Home);