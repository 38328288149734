import React, { useState } from 'react';
import { Grid, Box, Tabs, Tab, CircularProgress, Backdrop } from '@mui/material';
import { BackgroundCheckCardProps } from "../BackgroundCheckCard/BackgroundCheckCardProps";
import BackgroundCheckCard from "../BackgroundCheckCard/BackgroundCheckCard.component";
import {hashCode, useDataQuery} from '../../services/DataQueryService';
import { useAuth } from 'react-oidc-context';
import { useQueryClient } from '@tanstack/react-query';
import { useTabState } from '../../utils/tabUtils';
import {UserInfo} from "../Common/UserInfoResponse";

interface BackgroundCheckResponse {
  data: BackgroundCheckCardProps[];
  successful: boolean;
}

interface StatusTabsProps {
  period?: string | null | undefined;
  userInfo: UserInfo | undefined;
}

let activebBcRequestId: string;

const StatusTabs: React.FC<StatusTabsProps> = ({ period, userInfo }) => {
  const { selectedTab, handleTabChange } = useTabState('selectedBackgroundStatusTab', 1);
  const [cardData, setCardData] = useState<BackgroundCheckCardProps[]>([]);
  const [filteredCardData, setFilteredCardData] = useState<BackgroundCheckCardProps[]>([]);

  if (userInfo?.email == null)
    return <></>;
  
  const userHash = userInfo.email + userInfo?.companyId;
  const cardDataQuery = useDataQuery<BackgroundCheckResponse>(
    useAuth().user?.access_token,
    '/background-check',
    { period, userHash: hashCode(userHash) }
  );

  React.useEffect(() => {
    if (cardDataQuery.isSuccess) {
      setCardData(cardDataQuery.data.data);
      setFilteredCardData(cardDataQuery.data.data.filter(item => item.status === selectedTab));
    }
  }, [cardDataQuery.data, period]);

  const queryClient = useQueryClient();
  const handleDataRefresh = (bgcRequestId: string) => {
    activebBcRequestId = bgcRequestId;
    queryClient.removeQueries({ queryKey: ['/background-check'] });
    cardDataQuery.refetch();
  };

  const counts = {
    inProcess: cardData?.filter(item => item.status === 1).length || 0,
    submitted: cardData?.filter(item => item.status === 2).length || 0,
    invoiced: cardData?.filter(item => item.status === 3).length || 0,
    completed: cardData?.filter(item => item.status === 4).length || 0
  };

  React.useEffect(() => {
    setFilteredCardData(cardData?.filter(item => item.status === selectedTab));
  }, [selectedTab, cardData]);

  const cards = filteredCardData?.map((item: BackgroundCheckCardProps) => (
    <Grid item xs={12} sm={12} md={6} lg={4} key={item.bgcRequestId}>
      <BackgroundCheckCard {...{ ...item, onDataUpdated: handleDataRefresh }} />
    </Grid>
  ));
  
  return (
    <Box sx={{ bgcolor: 'background.paper', pl: '100px' }}>
      <Tabs
        value={selectedTab}
        onChange={(_, value) => handleTabChange(value)}
        TabIndicatorProps={{
          sx: { backgroundColor: 'black' }
        }}
      >
        <Tab sx={{ fontSize: '12px' }} value={1} label={`In Process (${counts.inProcess})`} />
        <Tab sx={{ fontSize: '12px' }} value={2} label={`Submitted (${counts.submitted})`} />
        <Tab sx={{ fontSize: '12px' }} value={3} label={`Invoiced (${counts.invoiced})`} />
        <Tab sx={{ fontSize: '12px' }} value={4} label={`Completed (${counts.completed})`} />
      </Tabs>

      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={cardDataQuery.isPending}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container spacing={1}>
        {cards}
      </Grid>
    </Box>
  );
};
export default StatusTabs;
