import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import * as React from 'react';
import {
    Stack,    
    CircularProgress,
    Box,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Switch
} from '@mui/material';
import DynamicQuestion, { Answer, Question } from '../DynamicQuestion/DynamicQuestion.component';
import { useDataQuery, useUpdateData } from '../../services/DataQueryService';
import { useAuth } from 'react-oidc-context';
import DefaultLegalComplianceCard from '../LegalComplianceCard/DefaultLegalComplianceCard.component';
import { useQueryClient } from '@tanstack/react-query';
import { Cancel, Delete } from '@mui/icons-material';
import { ErrorAlert } from '../Common/ErrorAlert';
import { Divider, Button, Typography } from 'bx-ui';


export interface DynamicFormProps {
    requestType: RequestType;
    requestId: string;
    entityId: string;
    questionnaire?: QuestionarieResponse;
    isEditable: boolean;
    setQuestionnaireOpen?: (value: boolean) => void;
    closeQuestionnaireRequested?: boolean;
    setCloseQuestionnaireRequested?: (value: boolean) => void;
}

export interface Section {
    id: string;
    text: string;
    nodes?: Section[];
    children?: Section[];
}

export interface QuestionarieResponse {
    data: {
        sections: Section[]
    };
}


export interface QuestionsResponse {
    data: Question[];
    successful: boolean;
}

export enum RequestType {
    BackgroundCheck = 'background-check',
    LegalCompliance = 'legal-compliance'
}

const QuestionComp = (props: { question: Question, onAnswersUpdated: (questionId: string, answers: Answer[]) => void; allowMulltipleAnswers: boolean, isEditable: boolean, answerIndex?: number }) => {
    const { question, onAnswersUpdated, allowMulltipleAnswers } = props;

    const capitalizeAfterDot = (text: string): string => {
        return text.split('.').map((part, index) =>
            index === 0 ? part : part.trim().charAt(0).toUpperCase() + part.trim().slice(1)
        ).join('. ');
    }

    return (
        <Grid item xs={12}>
            <DynamicQuestion
                questionText={capitalizeAfterDot(question.displayText)}
                answerType={(question.possibleAnswers && question.possibleAnswers.length > 1) ? 'SINGLE CHOICE'
                    : question.answerDataTypeName.toUpperCase()}
                options={(question.possibleAnswers && question.possibleAnswers.length > 1) ? question.possibleAnswers : []}
                answers={question.answers || []}
                setAnswers={(a) => onAnswersUpdated(question.questionId, a)}
                allowMulltipleAnswers={allowMulltipleAnswers}
                isEditable={props.isEditable}
                answerIndex={props.answerIndex}
            />
        </Grid>)
}

export const QuestionStack = (props: {
    questions: Question[],
    allowMulltipleAnswers: boolean,
    isEditable: boolean,
    onAnswersUpdated: (questionId: string, answers: Answer[]) => void,
    onResetAnswers: (questions?: Question[]) => void,
    onSaveAnswers: (answersToSave?: Answer[]) => void,
    hasChanges: boolean
}) => {

    const [groupAnswers, setGroupAnswers] = React.useState(false);

    function handleSaveAnswers() {
        props.onSaveAnswers()
    }

    function handleResetAnswers(): void {
        props.onResetAnswers();
    }

    return (
        <Typography bxVariant='bx-typography-body-primary'>
            <Box sx={{ height: '60vh', display: 'flex', flexDirection: 'column', pr: '40px' }}>
                <Box sx={{
                    flexGrow: 1, overflow: 'auto', p: 1,
                    border: '1px solid #e0e0e0',
                    borderRadius: 1,
                    backgroundColor: '#ffffff'
                }}>
                    {
                        groupAnswers || !props.allowMulltipleAnswers ?
                            (<Questions questions={props.questions} allowMulltipleAnswers={props.allowMulltipleAnswers} isEditable={props.isEditable} onAnswersUpdated={props.onAnswersUpdated} />) :
                            (<MultyQuestionsAnswers questions={props.questions} isEditable={props.isEditable} onAnswersUpdated={props.onAnswersUpdated} />)
                    }
                </Box>
                {props.isEditable && props.allowMulltipleAnswers && (<Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                    <Typography bxVariant='bx-typography-body-primary'>Answers grouped</Typography>
                    <Switch size='small' checked={groupAnswers} onChange={(e) => setGroupAnswers(e.target.checked)} />
                    <Typography bxVariant='bx-typography-body-primary'>Un-Grouped</Typography>
                </Stack>)}
                {props.isEditable && (
                    <Stack spacing={2} direction="row" display="flex" justifyContent="flex-end" sx={{ p: 1 }}>
                        <Button disabled={!props.hasChanges} onClick={handleSaveAnswers}>Save</Button>
                        <Button disabled={!props.hasChanges} onClick={handleResetAnswers}>Reset</Button>
                    </Stack>
                )}
            </Box>
        </Typography>
    );
}

const Questions = (props: {
    questions: Question[],
    allowMulltipleAnswers: boolean,
    isEditable: boolean,
    answerIndex?: number,
    onAnswersUpdated: (questionId: string, answers: Answer[]) => void
}) => {
    return (
    <Stack spacing={2}>
        {props.questions?.map(q => (
            <QuestionComp
                key={q.questionId}
                isEditable={props.isEditable}
                question={q}
                onAnswersUpdated={props.onAnswersUpdated}
                allowMulltipleAnswers={props.allowMulltipleAnswers}
                answerIndex={props.answerIndex}
            />
        ))}
    </Stack>)
}

const MultyQuestionsAnswers = (props: {
    questions: Question[],
    isEditable: boolean,
    answerIndex?: number,
    onAnswersUpdated: (questionId: string | null, answers?: Answer[] | string, removeIndex?: number) => void
}) => {

    const getMaxAnswerIndex = (questions: Question[]): number => {
        return questions.reduce((maxIndex, question) => {
            const questionMaxIndex = (question.answers?.length ?? 1) - 1;
            return Math.max(maxIndex, questionMaxIndex);
        }, 0);
    }

    const maxIndex = getMaxAnswerIndex(props.questions);

    const handleDeleteAnswer = (answerIndex: number) => {
        props.onAnswersUpdated(null, undefined, answerIndex);
    }

    const handleAddAnswer = () => {
        props.onAnswersUpdated(null, 'appendEmptyAnswer');
    }

    return (<Stack spacing={2}>
        {
            Array.from(Array(maxIndex + 1).keys()).map(i => {
                return (<span>
                    {props.isEditable && maxIndex > 0 && (<Stack spacing={2} direction="row" display="flex" justifyContent="flex-end" sx={{ p: 1 }}>
                        <Button onClick={() => handleDeleteAnswer(i)}>Delete Answer</Button>
                    </Stack>)}
                    <Questions questions={props.questions} allowMulltipleAnswers={true} isEditable={props.isEditable} onAnswersUpdated={props.onAnswersUpdated} answerIndex={i} />
                    <Divider />
                </span>)
            })
        }
        {props.isEditable && (
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                mt: 2,
                mb: 2
            }}>
                <Button
                    onClick={handleAddAnswer}
                    sx={{
                        minWidth: 'unset', // Allow button to size based on content
                        px: 2 // Add some horizontal padding
                    }}
                >
                    Add Answer
                </Button>
            </Box>
        )}

    </Stack>)
}

export const QuestionnaireSectionsBox = (props: { requestType: RequestType, sections: Section[], showExpandCollapseButton?: boolean, onSectionSelected?: (sectionId: string, hasChildren: boolean) => void }) => {
    const [expandedSections, setExpandedSections] = React.useState<string[]>(
        props.requestType === RequestType.BackgroundCheck
            ? []
            : [...props.sections.map(section => section.id)]
    );
    const apiRef = useTreeViewApiRef();

    const getAllSectionsWithChildrenSectionIds = () => {
        const itemIds: string[] = [];
        const registerItemId = (item: Section) => {
            if (item.children?.length) {
                itemIds.push(item.id);
                item.children.forEach(registerItemId);
            }
        };

        props.sections.forEach(registerItemId);

        return itemIds;
    };

    const handleExpandedSectionsChange = (itemIds: string[]) => {
        setExpandedSections(itemIds);
    };

    const handleExpandClick = () => {
        setExpandedSections((oldExpanded) =>
            oldExpanded.length === 0 ? getAllSectionsWithChildrenSectionIds() : [],
        );
    };

    const handleItemClick = (itemId: string) => {
        if (apiRef.current && props.onSectionSelected) {
            const item = apiRef.current?.getItem(itemId);
            const hasChildren = item?.children?.length;
            props.onSectionSelected(itemId, hasChildren);
        }
    };

    return (
    <Box sx={{ maxHeight: '100%', minWidth: '30%', maxWidth: '30%', overflow: 'auto' }}>
        {props.showExpandCollapseButton === true && (<Button size="small" component="label" onClick={handleExpandClick}>
            {expandedSections.length === 0 ? '+ Expand All' : '- Collapse All'}
        </Button>)}
        <RichTreeView
            apiRef={apiRef}
            items={props.sections}
            expandedItems={expandedSections}
            onExpandedItemsChange={props.showExpandCollapseButton ? (e, i) => handleExpandedSectionsChange(i) : undefined}
            getItemLabel={i => i.text}
            onItemClick={(event, itemId) => handleItemClick(itemId)} />
    </Box>)
}

const DynamicForm = (props: DynamicFormProps) => {

    const [sections, setSections] = React.useState<Section[]>([]);
    const [selectedSection, setSelectedSection] = React.useState<string>('');
    const [questions, setQuestions] = React.useState<Question[]>([]);
    const [hasChanges, setHasChanges] = React.useState(false);
    const [showDefaultLegalComplianceCard, setShowDefaultLegalComplianceCard] = React.useState<boolean>(false);
    const [confirmDiscardChangesDialogOpen, setConfirmDiscardChangesDialogOpen] = React.useState(false);

    const accesToken = useAuth().user?.access_token;

    const queryClient = useQueryClient();

    const setQuestionsWithDefaultAnswers = (questions: Question[]) => {
        const updatedQuestions = questions.map(q => {
            if (q.answers?.length) {
            } else {
                const answers = [{ answerText: '', sequenceNumber: -1, index: -1 }]
                q = {
                    ...q,
                    answers: answers
                };
            }

            return q;
        });

        setQuestions(updatedQuestions);
        setHasChanges(false);
    }

    const questionnaireDataQuery =
        props.requestType === RequestType.LegalCompliance
            ? {
                data: props.questionnaire,
                isLoading: false,
                isSuccess: true,
                isError: false,
                error: null,
                refetch: () => Promise.resolve(),
                isPending: false
            }
            : useDataQuery<QuestionarieResponse>(
                accesToken,
                `/${props.requestType}/${props.requestId}/questionnaire?entityId=${props.entityId}`
            );

    const questionsUrl = props.requestType === RequestType.LegalCompliance
        ? `/${props.requestType}/questionnaire/${selectedSection}`
        : `/${props.requestType}/${props.requestId}/questionnaire/${selectedSection}?entityId=${props.entityId}`

    const questionsDataQuery = useDataQuery<QuestionsResponse>(
        accesToken,
        questionsUrl,
        undefined,
        selectedSection
    );

    const updateAnswersMutation = useUpdateData();

    React.useEffect(() => {
        if (props.closeQuestionnaireRequested) {
            if (hasChanges) {
                setConfirmDiscardChangesDialogOpen(true);
            } else {
                if (props.setQuestionnaireOpen) props.setQuestionnaireOpen(false);
                if (props.setCloseQuestionnaireRequested) props.setCloseQuestionnaireRequested(false)
            }
        }
    }, [props.closeQuestionnaireRequested, hasChanges]);

    React.useEffect(() => {
        if (questionnaireDataQuery.isSuccess && questionnaireDataQuery.data) {
            const sections = questionnaireDataQuery.data.data.sections;
            setSections(sections.map((node) => {
                node.children = node.nodes;
                return node;
            }));

            if (sections.length > 0 && sections[0].children?.length) {
                if (props.requestType === RequestType.BackgroundCheck) {
                    setSelectedSection(sections[0].children[0].id);
                } else if (props.requestType === RequestType.LegalCompliance) {
                    setShowDefaultLegalComplianceCard(true);
                    setSelectedSection(sections[0].children[0].id);
                }
            }
        }
    }, [questionnaireDataQuery.isSuccess]);

    React.useEffect(() => {
        if (questionsDataQuery.isSuccess && selectedSection != '') {
            setQuestionsWithDefaultAnswers(questionsDataQuery.data.data);
        }
    }, [questionsDataQuery.isSuccess, selectedSection]);

    const loadQuestions = (itemId: string, hasChildren: boolean) => {
        if (!hasChildren && itemId !== selectedSection) {
            saveAnswers();
            setSelectedSection(itemId);
        }
        setShowDefaultLegalComplianceCard(props.requestType === RequestType.LegalCompliance && itemId === props.questionnaire?.data.sections[0].id);
    }

    const saveAnswers = (answersToSave?: Answer[], reloadQuestions?: boolean) => {

        if (!answersToSave) {
            answersToSave = [];
            questions.forEach(question => {
                if (question.isUpdated) {
                    const answers = question.answers?.map((a, i) => {
                        return { ...a, questionId: question.questionId, index: i };
                    });

                    if (answersToSave && answers) {
                        answersToSave.push(...answers);
                    }
                }
            });
        }

        if (!answersToSave.length) {
            return;
        }

        const updateUrl = (' ' + questionsUrl).slice(1);
        updateAnswersMutation.mutate({
            access_token: accesToken,
            method: 'POST',
            path: updateUrl,
            data: answersToSave
        },
            {
                onSuccess: (data) => {
                    if (reloadQuestions) {
                        setQuestionsWithDefaultAnswers(data.data);
                    }

                    queryClient.removeQueries({ queryKey: [updateUrl] });
                },
            });
    }

    const handleResetAnswers = async (qsts?: Question[]) => {
        if (!qsts) {
            const result = await questionsDataQuery.refetch();
            if (result.data?.successful) {
                qsts = result.data.data;
            }
        }

        updateAnswersMutation.reset();
        if (qsts) {
            setQuestionsWithDefaultAnswers(qsts);
        }
    }

    const handleUpdateAnswers = (questionId: string | null, answers?: Answer[] | string, removeIndex?: number) => {

        const appendEmptyAnswer = (answers as string) === 'appendEmptyAnswer';
        const updateAllAnswers = questionId === null;

        const appendEmptyAnswerIfneeded = (answers: Answer[]) => appendEmptyAnswer ? [...answers, { answerText: '', sequenceNumber: -1, index: -1 }] : answers;
        const removeAnswerIfNeeded = (answers: Answer[], removeIndex: number | undefined) => {
            if (removeIndex === undefined || answers.length === 0) {
                return answers;
            }

            const newAnswers = [...answers];
            newAnswers.splice(removeIndex, 1);
            return newAnswers;
        }

        const updatedQuestions = questions.map(q => {
            let newAnswers = answers;
            if (!(newAnswers instanceof Array)) {
                newAnswers = q.answers || [];
            }

            if (updateAllAnswers || q.questionId === questionId) {
                const updatedQuestion = {
                    ...q,
                    isUpdated: true,
                    answers: appendEmptyAnswerIfneeded(removeAnswerIfNeeded(newAnswers, removeIndex))
                };

                return updatedQuestion;
            }
            return q;
        });

        setHasChanges(true);
        setQuestions(updatedQuestions);
    }

    const handleDiscardChanges = function (discardChanges: boolean) {
        if (discardChanges) {
            if (props.closeQuestionnaireRequested) {
                if (props.setQuestionnaireOpen) props.setQuestionnaireOpen(false);
            }
            else {
                handleResetAnswers();
            }
        }

        if (props.setCloseQuestionnaireRequested) props.setCloseQuestionnaireRequested(false);
        setConfirmDiscardChangesDialogOpen(false);
    }

    return (
        <Box sx={{ p: 2 }}>

            {questionnaireDataQuery.isPending && (<CircularProgress color="info" />)}

            {updateAnswersMutation.isError && (
                <ErrorAlert
                    buttonText="X"
                    error={updateAnswersMutation.error}
                    onRetry={() => updateAnswersMutation.reset()}
                    customMessage="An error occurred while saving the answers."
                />
            )}

            {questionnaireDataQuery.isError && (
                <ErrorAlert
                    buttonText='Retry'
                    error={questionnaireDataQuery.error}
                    onRetry={() => questionnaireDataQuery.refetch()}
                    customMessage="An error occurred while loading the questionnaire."
                />
            )}

            {
                questionnaireDataQuery.isSuccess &&
                (<Stack spacing={2} direction="row">
                    {sections.length && (<QuestionnaireSectionsBox requestType={props.requestType} onSectionSelected={loadQuestions} sections={sections} showExpandCollapseButton={props.requestType === RequestType.BackgroundCheck} />)}
                    <Box sx={{ maxHeight: '100%', minWidth: '70%', overflow: 'auto' }}>

                        <Stack spacing={2} direction="column">
                            {questionsDataQuery.isPending && (<CircularProgress color="info" />)}

                            {
                                questionsDataQuery.isError && (
                                    <ErrorAlert
                                        buttonText='Retry'
                                        error={questionsDataQuery.error}
                                        onRetry={() => questionsDataQuery.refetch()}
                                        customMessage="An error occurred while loading the questions."
                                    />
                                )
                            }

                            {
                                showDefaultLegalComplianceCard ? (<DefaultLegalComplianceCard />) : questionsDataQuery.isSuccess &&
                                    (<QuestionStack
                                        questions={questions}
                                        allowMulltipleAnswers={props.requestType === RequestType.BackgroundCheck}
                                        onResetAnswers={() => setConfirmDiscardChangesDialogOpen(true)}
                                        onAnswersUpdated={handleUpdateAnswers}
                                        onSaveAnswers={(a) => saveAnswers(a, true)}
                                        isEditable={props.isEditable}
                                        hasChanges={hasChanges && (!updateAnswersMutation.isPending && !questionnaireDataQuery.isPending)} />)
                            }
                        </Stack>
                    </Box>
                </Stack>)
            }

            <Dialog
                open={confirmDiscardChangesDialogOpen}
                onClose={() => handleDiscardChanges(false)}
                maxWidth="xs"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confimation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You have un-saved answers. Are you sure you want to discard your changes?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<Cancel />}
                        onClick={() => handleDiscardChanges(false)}>
                        Cancel
                    </Button>
                    <Button startIcon={<Delete />}
                        onClick={() => handleDiscardChanges(true)}
                        autoFocus>
                        Discard changes
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>);
};

export default DynamicForm;
