import React from 'react';
import { TextField } from '@mui/material';

interface NumericAnswerProps {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}

const NumericAnswer: React.FC<NumericAnswerProps> = ({ value, onChange, disabled=false }) => {
  return (
    <TextField
      type="number"
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
      disabled={disabled}
      sx={{ 
        width: '10%',
        minWidth: '10px'
      }}
      variant="outlined"
      size="small"
    />
  );
};

export default NumericAnswer;
