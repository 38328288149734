import React from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio, Box } from '@mui/material';

interface SingleChoiceAnswerProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  options: string[];
}

const SingleChoiceAnswer: React.FC<SingleChoiceAnswerProps> = ({ value, onChange, disabled=false, options }) => {
  return (
    <FormControl sx={{ width: '70%' }}>
      <RadioGroup
        value={value}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option}
            value={option}
            control={<Radio disabled={disabled} />}
            label={option}
            sx={{ 
              whiteSpace: 'nowrap',
              marginRight: 0 
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default SingleChoiceAnswer;