import React, { useState } from 'react';
import {
    ListItemText,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    FormControl,
    Snackbar,
    SnackbarCloseReason,
    IconButton,
    Alert,
    CircularProgress
} from '@mui/material';
import { Button, List, ListItem } from 'bx-ui'
import { Delete, UploadFile, Cancel } from '@mui/icons-material';
import VisuallyHiddenInput from '../Common/VisuallyHiddenInput';
import { Document, DocumentList, DocumentType } from '../Common/Document';
import { useUploadDocument, useDeleteDocument } from '../../services/DataQueryService';
import { useAuth } from "react-oidc-context";
import { downloadDocument } from '../../utils/documentUtils';
import { useSnackbar } from '../Common/SnackbarProvider';

const BackgroundCheckAttachmentsTab = (props: DocumentList) => {
    const uploadMutation = useUploadDocument();
    const deleteMutation = useDeleteDocument();
    const access_token = useAuth().user?.access_token;
    const bgcrid = props.bgcRequestId || '';
    const documentType = props.bgcRequestId ? DocumentType.BackgroundCheckAttachment : DocumentType.LegalComplianceAttachment;
    const isEditable = props.isEditable;
    const [attachmentList, setAttachmentList] = useState<Document[]>(props.documents || []);
    const [selectedDeletionAttachment, setSelectedDeletionAttachment] = useState<Document | null>(null);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const { showSnackbar, closeSnackbar } = useSnackbar();

    const [toast, setToast] = React.useState<{ isOpen: boolean; message: string; severity: "error" | "success" | "info" | "warning"; }>({
        isOpen: false,
        message: '',
        severity: 'info'
    });

    const handleAttachmentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        setInProgress(true);
        uploadMutation.mutate({
            access_token,
            bgcrid,
            documentType: documentType,
            file
        },
            {
                onSuccess: (data: any) => {
                    const uploadedFile: Document = data.data as Document;
                    attachmentList.push(uploadedFile);
                    setAttachmentList(attachmentList);
                    props.onAttachmentsChange?.(bgcrid, attachmentList);
                    setInProgress(false);
                    showSnackbar('Attachment successfully uploaded', 'success');
                },
                onError: (error: Error) => {
                    setInProgress(false);
                    showSnackbar(error.message, 'error');
                }
            });
    };

    const handleDialogDelete = (isConfirm: boolean) => {
        if (isConfirm && selectedDeletionAttachment != null && selectedDeletionAttachment.id != null) {
            let documentId = selectedDeletionAttachment.id;
            setInProgress(true);
            // send delete to server
            deleteMutation.mutate({
                access_token,
                bgcrid,
                documentType: documentType,
                documentId
            },
                {
                    onSuccess: (data: any) => {
                        // remove attachment from list
                        const deletedAttachmentIndex = attachmentList.findIndex(attachment => attachment.id === selectedDeletionAttachment?.id);
                        attachmentList.splice(deletedAttachmentIndex, 1);
                        setAttachmentList(attachmentList);
                        setSelectedDeletionAttachment(null);
                        props.onAttachmentsChange?.(bgcrid, attachmentList);
                        setInProgress(false);
                        showSnackbar('Attachment successfully deleted', 'success');
                    },
                    onError: (error: Error) => {
                        setSelectedDeletionAttachment(null);
                        setInProgress(false);
                        showSnackbar(error.message, 'error');
                    }
                });
        } else {
            setSelectedDeletionAttachment(null);
        }
    };

    const handleAttachmentClick = (attachmentId: string) => {
        downloadDocument(access_token, documentType, bgcrid, attachmentId);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {attachmentList && attachmentList.length > 0 && (
                <List sx={{ marginBottom: 2, height: '360px', overflow: 'auto', boxShadow: 'none !important' }}>
                    {attachmentList.map((attachment, index) => (
                        <ListItem key={index}
                            secondaryAction={
                                <>
                                    <IconButton edge="end"
                                        aria-label="delete"
                                        disabled={!isEditable}
                                        onClick={() => setSelectedDeletionAttachment(attachment)}>
                                        {inProgress && selectedDeletionAttachment != null ?
                                            <CircularProgress size="20px" /> :
                                            <Delete />
                                        }
                                    </IconButton>
                                </>
                            }>
                            <ListItemText sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                                primary={attachment.fullName}
                                secondary={attachment.created}
                                onClick={() => handleAttachmentClick(attachment!.id!)} />
                        </ListItem>
                    ))}
                </List>
            )}

            {isEditable && (
                <div style={{
                    display: 'flex', justifyContent: 'flex-end', marginTop: 'auto',
                    padding: '4px', paddingBottom: '60px'
                }}><Button variant="contained"
                    component="label"
                    endIcon={inProgress && selectedDeletionAttachment == null ?
                        <CircularProgress size="20px" /> :
                        <UploadFile />
                    }>
                        Add Attachment
                        <VisuallyHiddenInput
                            type='file'
                            id='selectedFile'
                            onChange={(event) => handleAttachmentUpload(event)} />
                    </Button>
                </div>
            )}

            <Dialog
                open={selectedDeletionAttachment != null && !inProgress}
                onClose={() => handleDialogDelete(false)}
                maxWidth="xs"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confimation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the following attachment: "{selectedDeletionAttachment?.fullName}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<Cancel />}
                        onClick={() => handleDialogDelete(false)}>
                        Cancel
                    </Button>
                    <Button startIcon={<Delete />}
                        onClick={() => handleDialogDelete(true)}
                        autoFocus>
                        Delete Attachment
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default BackgroundCheckAttachmentsTab;
