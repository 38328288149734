import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { parseDateFromString, formatDateToString } from '../../utils/dateUtils';

interface DatePickerProps {
  onDateChange: (date: string | null) => void;
  views?: Array<'year' | 'month' | 'day'>;
  openTo?: 'year' | 'month' | 'day';
  value?: string | null;
}

const DatePickerComponent: React.FC<DatePickerProps> = ({ value = null, onDateChange, views = ['year', 'month'], openTo = 'year' }) => {
  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs | null>(
    parseDateFromString(value)
  );

  const handleDateChange = (newValue: dayjs.Dayjs | null) => {
    const adjustedValue = newValue && !views.includes('day') 
    ? newValue.date(1) 
    : newValue;

    setSelectedDate(adjustedValue);
    onDateChange(formatDateToString(adjustedValue));
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={views}
        openTo={openTo}
        value={selectedDate}
        onChange={handleDateChange}
        slotProps={{
          textField: {
            placeholder: 'Enter Date Range'
          }
        }}
        sx={{
          backgroundColor: 'white',
          width: '200px',
          height: '35px',
          marginRight: '16px',
          '& .MuiInputBase-root': {
            height: '35px'
          }
        }}
      />    </LocalizationProvider>
  );
};export default DatePickerComponent;