import {
  Toolbar,
  AppBar,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AppBar.module.scss';
import BlackstoneLogo from '../../assets/Blackstone.png';
import { useAuth } from "react-oidc-context";
import RefreshIcon from '@mui/icons-material/Refresh';
import Impersonation from "../Impersonation/Impersonation.component";
import {Roles, UserInfo} from "../Common/UserInfoResponse";

export interface IAppBarItem {
  name: string;
  route: string;
}

export const AppBarComponent: React.FC<{userInfo: UserInfo | undefined}> = ({userInfo}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  
  var curProfile = auth.user?.profile;

  const getAvatar = () => {
    const picture = curProfile?.picture;
    if (!!picture) { return picture; }
    const firstName = curProfile?.given_name;
    const lastName = curProfile?.family_name;
    return !!firstName && !!lastName ? `${firstName.substring(0, 1)}${lastName.substring(0, 1)}` : undefined;
  } // abstract to auth service, including code to get image 

  const appBarItems: IAppBarItem[] = [
    ...(userInfo?.hasRole(Roles.Tester) || 
          userInfo?.hasRole(Roles.LegalCompliance) || 
          userInfo?.hasRole(Roles.BackgroundCheck) ? [{ name: 'Dashboard', route: '/' },] : []),
    ...(userInfo?.hasRole(Roles.Tester) ? [{ name: 'Healthcheck', route: '/healthcheck' }] : []),
    ...(userInfo?.hasRole(Roles.Admin) ? [{ name: 'Admin', route: '/admin' }] : [])
  ];
  const handleRefresh = () => {
    window.location.reload();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  if (!userInfo) return <></>;

  return (
    <div className={styles.appbarContainer}>
      <AppBar className={styles.appbar} position="static">
        <div className={styles.blackstone_img_container}>
          <img src={BlackstoneLogo} className={styles.blackstone_img} onClick={() => navigate('/')} />
          <span className={styles.toolbar_title}>External Data Provider</span>
          <div className={styles.toolbar_item_container}>
            {auth.isAuthenticated && (<Toolbar>
              <div className={styles.toolbar_item_container} style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Tooltip title="Refresh">
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={handleRefresh}
                  >
                    <RefreshIcon sx={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>

                {appBarItems.map((item, index) =>
                  <a key={index} className={styles.toolbar_item_inactive} onClick={() => navigate(item.route)}>{item.name}</a>
                )}

                <Impersonation />

                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    sx={{ p: 0 }}
                  >
                    <Avatar alt="Remy Sharp" src={curProfile?.picture || ''}>{getAvatar()}</Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    sx: {
                      width: '100px'
                    }
                  }}
                >
                  <MenuItem onClick={() => navigate('/profile')}>
                    <PersonIcon />
                    Profile
                  </MenuItem>
                  <MenuItem onClick={() => { localStorage.clear(); auth.removeUser() }}>
                    <LogoutIcon />
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
            )}
          </div>
        </div>
      </AppBar>

    </div>
  );
}

export default AppBarComponent;