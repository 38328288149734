import React from 'react';
import ReactDOM from 'react-dom/client';
import Config from  './config/appConfig';
import App from './App';
import { AuthProvider } from "react-oidc-context";
import { User } from "oidc-client-ts";

const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    )
}

const oidcConfig = Config.OidcConfigs[Config.AuthMethod];

const oidcSettings = {
  authority: oidcConfig.Authority,
  client_id: oidcConfig.ClientID,
  redirect_uri: `${window.location.origin}/login`,
  response_type: oidcConfig.ResponseType,
  scope: oidcConfig.Scope,
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.origin}/silent-refresh.html`,
  post_logout_redirect_uri: oidcConfig.LogoutRedirectUri,
  loadUserInfo: oidcConfig.loadUserInfo,
  onSigninCallback: onSigninCallback,
  extraQueryParams: {
    audience: oidcConfig.Authority
  },
  metadataUrl: `${oidcConfig.Authority}/.well-known/openid-configuration`
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
<AuthProvider {...oidcSettings}>
  <App />
</AuthProvider>
);

