import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Roles, UserInfo} from "./UserInfoResponse";

const AuthRedirector : React.FC<{userInfo: UserInfo | undefined}>= ({userInfo}) => {
     
    const navigate = useNavigate();

    useEffect(() => {
        if (userInfo) {
            if (userInfo.hasRole(Roles.Admin) && 
                !userInfo.hasRole(Roles.Tester) &&
                !userInfo.hasRole(Roles.BackgroundCheck) &&
                !userInfo.hasRole(Roles.LegalCompliance))
                navigate("/admin", {replace: true});
        }
    }, [userInfo, navigate]);

    return null; // No UI needed, just handles redirection
};

export default AuthRedirector;
