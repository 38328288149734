import React, { createContext, useState, useEffect, useContext } from 'react';
import Config from "../config/appConfig";
import { useAuth } from "react-oidc-context";
import {
    UserInfo,
    ImpersonationContact, Roles
} from "../components/Common/UserInfoResponse";
import { handleResponse } from "./DataQueryService";

export interface UserInfoContextType {
    userInfo: UserInfo | undefined;
    fetchUserInfo: (includeImpersonationContacts: boolean) => Promise<void>;
}

const UserInfoContext = createContext<UserInfoContextType | undefined>(undefined);

const ImpersonationContactsContext = createContext<ImpersonationContact[] | undefined>(undefined);

export const UserInfoProvider = ({ children } : {children: any}) => {
    const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
    const [impersonationContacts, setImpersonationContacts] = useState<ImpersonationContact[] | undefined>(undefined);
    const { user } = useAuth();
    const fetchUserInfo = async (includeImpersonationContacts: boolean = true) => {
        const response = await fetch(`${Config.DataApiUrl}/my/info?includeImpersonateUsers=${includeImpersonationContacts}`, {
            headers: {
                'Authorization': `Bearer ${user?.access_token}`
            }
        });

        try {
            let result = await handleResponse(response);
            
            result.data.hasRole = function (role: string): boolean {
                return Roles.hasRole(this, role);
            }

            setUserInfo(result.data);
            if (result.data.impersonationContacts) {
                setImpersonationContacts(result.data.impersonationContacts);
            }
        } catch (error) {
            const failedUserInfo: Partial<UserInfo> = {
                hasRole: function (role: string): boolean {
                    return false;
                },
                error: error as Error
            }
            setUserInfo(failedUserInfo as UserInfo);
        }
    };
    
    useEffect(() => {
        if (user != null) {
            fetchUserInfo();
        }
    }, [user]);

    return (
        <UserInfoContext.Provider value={{ userInfo, fetchUserInfo }}>
            <ImpersonationContactsContext.Provider value={impersonationContacts}>
                {children}
            </ImpersonationContactsContext.Provider>
        </UserInfoContext.Provider>
    );
};

export const useUserInfoContext = () : UserInfoContextType | undefined => {
    return useContext(UserInfoContext);
};

export const useImpersonationContactsContext = () : ImpersonationContact[] | undefined => {
    return useContext(ImpersonationContactsContext);
};
