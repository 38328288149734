import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

export const useAuthCheck = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const checkAuthAndRedirect = (): boolean => {
        if (!auth.isAuthenticated || auth.user?.expired) {
            localStorage.clear();
            auth.removeUser();
            navigate('/login');
            return false;
        }
        return true;
    };

    return { checkAuthAndRedirect, auth };
}; 
