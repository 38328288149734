import StatusTabs from "../StatusTabs/StatusTabs.component";
import LegalComplianceCard from "../LegalComplianceCard/LegalComplianceCard.component";
import React from "react";

export interface UserInfoResponse {
    data: UserInfo
}

export interface UserInfo {
    fullName: string,
    email: string,
    roles: string[],
    companyId: string,
    companyName: string,
    isLegalComplaince: boolean,
    isBackgroundCheck: boolean,
    isAdminUser: boolean,
    isTestUser: boolean,
    impersonationContacts?: ImpersonationContact[],
    hasRole: (role: string) => boolean,
    error?: Error
}

export class Roles {
    static readonly Admin: string = 'Admin';
    static readonly Tester: string = 'Tester';
    static readonly User: string = 'User';

    static readonly BackgroundCheck: string = 'BackgroundCheck';
    static readonly LegalCompliance: string = 'LegalCompliance';
    
    static hasRole(userInfo: UserInfo | undefined, role: string) : boolean {
        if (!userInfo) return false;
        return userInfo.roles?.includes(role);
    }
}

export interface ImpersonationContact {
    firstName: string;
    middleName: string;
    lastName: string;
    companyId: number;
    companyName: string;
    email: string;
    isLegalComplaince: boolean;
    isBackgroundCheck: boolean;
}